import React from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { rem, colors, snippets, mq, easing, remMobile } from 'styles/helpers'
import l10n from 'core/l10n'

const Wrapper = styled.div`
  margin: ${rem(10)}  0;
  font-size: 1.3em;
`

const Link = styled.a`
  font-weight: bold;
  text-decoration: underline;
`

const Offer = ({ text = l10n('common.discover-offers'), link, ...props }) => {
  return (
    <Wrapper { ...props }>
      {text}{' '}
      <Link href={ link } target='_blank' rel='noopener'>{/* eslint-disable-line react/jsx-no-target-blank */}
        {l10n('common.click')}
      </Link>
    </Wrapper>
  )
}

export default React.memo(Offer)
