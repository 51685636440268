import React, { useCallback, useMemo } from 'react'

import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { colors, mq, rem, remMobile } from 'styles/helpers'
import l10n from 'core/l10n'
import Button from 'components/button/Button'
import persistentStore from 'stores/persistentStore'
import { connect } from 'helpers/state'
import ga from 'helpers/ga'

const CustomButton = styled(Button)`
  border-color: ${colors.green} !important;
  color: ${colors.green} !important;
  line-height: 1.2;
  margin-left: auto;
  margin-right: auto;

  ${mq.tabletP} {
    font-size: ${remMobile(35)};
  }
`
const Svg = styled.svg`
  width: ${rem(68)};
  height: ${rem(68)};
  flex-shrink: 0;
  margin-left: ${rem(20)};

  ${mq.tabletP} {
    width: ${remMobile(85)};
    height: ${remMobile(85)};
    margin-left: ${remMobile(30)};
  }

  path {
    stroke: ${colors.green};
    stroke-width: 3.8;
  }
`

const StartButton = ({ episodeId, updateStoreValue }) => {
  const status = useMemo(() => persistentStore[episodeId].get(), [episodeId])
  const button = useMemo(() => {
    if (!episodeId) return
    return l10n(status < 0 ? 'common.restart' : status > 0 ? 'common.resume' : 'common.start')
  }, [episodeId])

  // const onClick = async (event) => {
  //   const deffered = promise.defer()
  //   updateStoreValue('playPromise', deffered)
  //   const player = await deffered.promise
  //   const play = player.play()
  //   play.catch((e) => {
  //     alert(e)
  //   })
  // }

  return (
    <CustomButton as={ Link } text={ button } to={ `/episode/${episodeId}` }>
      <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 56'>
        <path fill='none' d='M21.65 17.23l17.05 10.8-17.05 10.8v-21.6z' className='st0' />
        <path fill='none' d='M28.02 1.9h.01a26.12 26.12 0 0126.12 26.12v.01a26.12 26.12 0 01-26.12 26.12h-.01A26.12 26.12 0 011.9 28.03v-.01A26.12 26.12 0 0128.02 1.9z' className='st0' />
      </Svg>
    </CustomButton>
  )
}

export default connect()(React.memo(StartButton))
