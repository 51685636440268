/* global tC */

import React, { useCallback } from 'react'

import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import { easing, mq, rem, remMobile, sizes } from 'styles/helpers'
import l10n from 'core/l10n'
// import tarteaucitron from 'vendors/tarteaucitron/tarteaucitron'
import { baseAnimation, fadeAnimation } from 'core/animation'
import { connect } from 'helpers/state'
import tc from 'helpers/tc'

const Wrapper = styled(motion.footer)`
  display: flex;

  ${mq.notTabletP} {
    position: fixed;
    bottom: ${rem(44)};
    left: ${rem(sizes.leftOffset)};
    z-index: 10;
  }

  ${mq.tabletP} {
    flex-flow: wrap;
    justify-content: flex-end;

    /* padding-left: 10%; */
  }
`

const Button = styled.button`
  font-size: ${rem(14)};
  margin-right: ${rem(10)};
  font-family: inherit;
  line-height: inherit;
  padding: 0;

  ${mq.tabletP} {
    font-size: ${remMobile(40)};
    white-space: nowrap;
    line-height: 2em;
    text-decoration: underline;
    margin-right: 0;
    margin-left: ${rem(10)};
  }

  ${mq.hover} {
    transition: opacity .3s ${easing.swiftOut};

    &:hover {
      opacity: .5;
    }
  }
`
const LinkButton = styled(Button)``.withComponent(Link)
const fade = Object.assign(fadeAnimation({}), baseAnimation)

const Footer = ({ mqTabletP }) => {
  const openCookies = useCallback(() => {
    tc.click('gestion_des_cookies')
    tc.page({ page: 'gestion_des_cookies' })
    tC && tC.privacyCenter.showPrivacyCenter()
  }, [])

  return (
    <Wrapper { ...fade }>
      <LinkButton to='/legals' onClick={ () => tc.click('mentions_legales') }>{l10n('footer.legals')}</LinkButton>
      <LinkButton to='/credits' onClick={ () => tc.click('credits') }>{l10n('footer.credits')}</LinkButton>
      <LinkButton to='/privacy' onClick={ () => tc.click('protection_des_donnees_personnelles') }>{l10n('footer.privacy')}</LinkButton>
      <LinkButton to='/cookie-policy' onClick={ () => tc.click('politique_des_cookies') }>{l10n('footer.cookie-policy')}</LinkButton>
      <Button onClick={ openCookies }>{l10n('footer.cookies')}</Button>
    </Wrapper>
  )
}

export default connect('mqTabletP')(React.memo(Footer))
