import React, { useCallback, useEffect, useMemo } from 'react'

import styled from '@emotion/styled'
import { Link, useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { css } from '@emotion/react'

import { colors, easing, mq, rem, remMobile, snippets } from 'styles/helpers'
import { connect } from 'helpers/state'
import share from 'helpers/share'
import l10n from 'core/l10n'
import { baseAnimation, fadeAnimation, menuAnimation } from 'core/animation'
import Footer from 'components/footer/Footer'
import tc from 'helpers/tc'

import MenuButton from './components/MenuButton'

const Wrapper = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100% - ${rem(100)});
  padding: ${rem(40)} ${rem(60)};
  padding-right: ${rem(30)};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  z-index: 90;
  width: ${rem(545)};
  background: ${colors.green};
  border-radius: 0 0 ${rem(110)} 0;
  box-shadow: 0 ${rem(-10)} ${rem(10)} ${rem(0)} rgba(0, 0, 0, .2);

  ${mq.tabletP} {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    padding: ${remMobile(20)} ${remMobile(60)};
    overflow: scroll;
  }

  &::before {
    content: '';
    width: 100%;
    display: block;
    height: 0;
  }

  &::after {
    content: '';
    position: absolute;
    background-color: transparent;

    bottom: ${rem(-200)};
    left: 0;
    height: ${rem(200)};
    width: ${rem(100)};
    border-top-left-radius: ${rem(100)};
    box-shadow: 0 ${rem(-100)} 0 0 ${colors.green};
    transform: scaleX(1.3);
    transform-origin: top left;

    ${mq.tabletP} {
      display: none;
    }
  }
`

const Inner = styled.div`
  ${mq.tabletP} {
    min-height: ${rem(500)};
    padding: ${rem(60)} 0;
  }
`

const MenuFooter = styled.div`
  width: 100%;
`

const Share = styled.div`
  color: ${colors.white};
  font-size: ${rem(18.5)};
  text-align: center;
  width: 100%;
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mq.tabletP} {
    font-size: ${remMobile(40)};
    text-align: right;
    margin-top: ${rem(5)};
    line-height: 2em;
    justify-content: flex-end;
  }
`

const ShareButton = styled.button`
  display: inline-block;
  width: ${rem(28)};
  height: ${rem(28)};
  border-radius: 50%;
  background-color: ${colors.white};
  overflow: hidden;
  margin-left: ${rem(10)};
  text-indent: 99em;
  white-space: nowrap;
  position: relative;
  transition: opacity .3s ${easing.swiftOut};

  &::after {
    position: absolute;
    top: ${rem(4)};
    left: ${rem(6)};
    width: ${rem(16)};
    height: ${rem(20)};
    content: '';
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('/assets/svg/${p => p.icon}.svg');
  }

  ${mq.hover} {
    transition: opacity .3s ${easing.swiftOut};

    &:hover {
      opacity: .5;
    }
  }

`

const Overlay = styled(motion.span)`
  display: block;
  background: rgba(0, 0, 0, .3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 89;
  cursor: pointer;
`

const Home = styled(Link)`
  position: absolute;
  height: ${rem(54)};
  right: ${rem(30)};
  top: ${rem(30)};
  padding-right: ${rem(70)};
  display: flex;
  align-items: center;
  justify-items: flex-start;
  font-size: ${rem(28)};
  color: ${colors.green2};
  font-weight: bold;

  background-image: url('/assets/svg/home.svg');
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: opacity .3s ${easing.swiftOut};

  ${mq.tabletP} {
    /* width: ${rem(35)}; */
    font-size: ${rem(20)};
    padding-right: ${rem(45)};
    height: ${rem(35)};
    right: ${rem(90)};
    top: ${rem(15)};
  }

  ${snippets.hover('opacity: .5;')}

`

const animation = menuAnimation()
const fade = Object.assign({}, fadeAnimation(), baseAnimation)

const Menu = ({ menuOpened, updateStoreValue, history, mqTabletP }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (menuOpened) updateStoreValue('menuOpened', false)
  }, [pathname])

  const end = [
    { to: '/conseils', name: l10n('advices.title'), advices: 1 },
    { to: '/a-propos', name: l10n('about.title'), about: 1 }
  ]

  const links = useMemo(() => {
    return [...l10n('episodes').map(({ id, name, subject, label }) => ({ to: `/episode/${id}`, name, subject: mqTabletP ? subject : label, episode: 1 })), ...end]
  }, [mqTabletP])

  const close = useCallback(() => updateStoreValue('menuOpened', false), [])

  return (
    <AnimatePresence>
      {menuOpened && (
        <Wrapper key='wrapper' { ...animation }>
          <Inner>
            <Home to='/' onClick={ () => tc.click('menu-home') }>{l10n('menu.home')}</Home>
            {links.map((props) => (
              <MenuButton key={ props.to } { ...props } />
            ))}
          </Inner>
          <MenuFooter>
            {mqTabletP && <Footer />}
            <Share>
              {/* {l10n('common.share')}
              <ShareButton icon='facebook' onClick={ () => share.facebook() }>facebook</ShareButton>
              <ShareButton icon='twitter' onClick={ () => share.twitter(l10n('share.twitter')) }>twitter</ShareButton>
              <ShareButton icon='linkedin' onClick={ () => share.linkedin() }>linkedin</ShareButton> */}
            </Share>
          </MenuFooter>
        </Wrapper>
      )}
      {menuOpened && (
        <Overlay onClick={ close } key='overlay' { ...fade } />
      )}
    </AnimatePresence>
  )
}

export default connect('menuOpened, mqTabletP')(React.memo(Menu))
