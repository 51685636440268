import ga from 'helpers/ga'
import tc from 'helpers/tc'

export default {
  chooseSequence: (state, key) => {
    const { episode, sequence } = state
    const seq = episode.sequences[sequence]

    const nextSequence = state.sequence + 1
    const score = state[key] + 1

    ga.event('sequence-choice', { id: episode.id, choice: key, sequence: nextSequence })

    try {
      const choiceKey = key.slice(-1).toLowerCase()
      const choice = episode.choices[state.sequence][choiceKey]
      tc.page({ page: 'etape_' + (nextSequence + 1), page_chapter1: 'saison_1', page_chapter2: episode.name.toLowerCase(), choix: choice.title })
    } catch (error) {}

    return {
      choice: false,
      videoSrc: seq[key],
      nextSequence,
      nextOffset: seq[`${key}Offset`],
      [key]: score,

      // Persistent
      [episode.id]: nextSequence,
      [episode.id + '-' + key]: score,
      [episode.id + '-previous-choice']: key,
      [episode.id + '-previous-duration']: state.duration
    }
  },
  initSequence: (state, duration, videoSrc) => {
    const { episode } = state

    const updated = {
      time: 0,
      duration: duration,
      sequence: state.nextSequence,
      offset: state.nextOffset,
      force: true
    }

    try {
      const res = videoSrc.match(/medias\/(.*)\/files/)
      const mediaId = res[1]

      if (mediaId) {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', '//cdn.streamlike.com/o.k?m=' + mediaId + '&t=' + Date.now() + '&s=' + 'hls', true)
        xhr.send(null)
      }
    } catch (error) {}

    // Reset persistent
    if (state.nextSequence === 0) {
      updated[episode.id] = 0
      updated[episode.id + '-choiceA'] = 0
      updated[episode.id + '-choiceB'] = 0
      updated[episode.id + '-previous-choice'] = null
      updated[episode.id + '-previous-duration'] = null
    }

    return updated
  },
  endSequence: (state) => {
    const { episode, sequence } = state
    const seq = episode.sequences[sequence]

    ga.event('sequence-end', { id: episode.id, sequence: sequence + 1 })

    if (seq) {
      return { choice: true }
    } else {
      return {
        end: true,

        // Persistent
        [episode.id]: -1
      }
    }
  }
}
