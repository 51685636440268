import { useRef, useEffect, useState } from 'react'

import { debounce } from 'lodash-es'

import detect from './detect'

let cache = {}

let root = false
let ignoreScrollResize = false

const dimension = () => {
  if (cache.dimension) return cache.dimension

  const dimension = {
    width: !root ? window.innerWidth : root.offsetWidth,
    innerWidth: document.body.offsetWidth,
    height: window.innerHeight
    // height: !root ? window.innerHeight : root.offsetHeight
  }

  dimension.scrollWidth = dimension.width - dimension.innerWidth

  // Ignore ios resize on scroll (wheen bottom bar disappear)
  if (ignoreScrollResize &&
    detect.iphone &&
    cache.dimension &&
    cache.dimension.width === dimension.width)
    dimension.height = cache.dimension.height

  dimension.ratio = dimension.width / dimension.height

  return (cache.dimension = dimension)
}

const width = () => dimension().width
const height = () => dimension().height
const ratio = () => dimension().ratio
const scrollWidth = () => dimension().scrollWidth
const outerWidth = () => dimension().outerWidth
const testMQ = (mq) => () => cache[mq] || (cache[mq] = window.matchMedia(mq).matches)

const clear = () => {
  cache = {}
}

let listeners = []

const resize = () => {
  resizeDebounce()
}

const resizeDebounce = debounce(() => {
  clear()
  listeners.forEach((listener) => listener && listener.resize && listener.resize())
}, 100)

window.addEventListener('resize', resize)
window.addEventListener('orientationchange', resize)

const add = (listener) => listeners.push(listener)
const remove = (listener) => (listeners = listeners.filter(item => item !== listener))

const react = (Target) => {
  const componentDidMount = Target.prototype.componentDidMount
  Target.prototype.componentDidMount = function () {
    add(this)
    this.resize && this.resize()
    componentDidMount && componentDidMount.call(this)
  }

  const componentWillUnmount = Target.prototype.componentWillUnmount
  Target.prototype.componentWillUnmount = function () {
    remove(this)
    componentWillUnmount && componentWillUnmount.call(this)
  }

  return Target
}

const useResize = (callback) => {
  const savedCallback = useRef({ resize: null })

  useEffect(() => {
    callback()
    add(savedCallback.current)
    return () => remove(savedCallback.current)
  }, [])

  useEffect(() => {
    savedCallback.current.resize = callback
  }, [callback])
}

const useSize = () => {
  const [size, setSize] = useState([width(), height()])

  useResize(() => {
    setSize([width(), height()])
  })

  return size
}

const setRoot = r => (root = r)
const setIgnoreScrollResize = i => (ignoreScrollResize = !!i)

export { useResize, useSize }

export default {
  dimension,
  scrollWidth,
  outerWidth,
  ratio,
  width,
  height,
  clear,
  react,
  useResize,
  useSize,
  add,
  remove,
  setRoot,
  setIgnoreScrollResize,
  resize,
  testMQ
}
