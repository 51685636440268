import React, { useEffect } from 'react'

import { LegalPage, LegalParagraph, LegalTitle } from 'sections/legals/Legals'
import CloseButton from 'components/close-button/CloseButton'
import { pageAnimation2 } from 'core/animation'
import tc from 'helpers/tc'

const anim = pageAnimation2()

const Credits = () => {
  useEffect(() => { tc.page({ page: 'credit' }) }, [])

  return (
    <LegalPage { ...anim }>

      <CloseButton />

      <LegalTitle> Crédits</LegalTitle>
      <LegalParagraph>
        A Kitchen Paris<br />
        <a href='https://www.akitchen.fr/' target='_blank' rel='noreferrer'>akitchen.paris</a>
      </LegalParagraph>
    </LegalPage>
  )
}

export default React.memo(Credits)
