import React, { useEffect } from 'react'

import { motion } from 'framer-motion'
import styled from '@emotion/styled'

import { snippets } from 'styles/helpers'
import l10n from 'core/l10n'
import PageBlock from 'components/page-layout/components/PageBlock'
import PageLayout from 'components/page-layout/PageLayout'
import { pageAnimation } from 'core/animation'
import tc from 'helpers/tc'

const Page = styled(motion.section)`
  ${snippets.absolute('top, left, right, bottom')}
`

const animation = pageAnimation()

const Home = () => {
  useEffect(() => { tc.page({ page: 'accueil' }) }, [])

  return (
    <Page { ...animation }>
      <PageLayout background={ l10n('home.video') }>
        <PageBlock key='default' title={ l10n('home.title') } subtitle={ l10n('home.subtitle') } description={ l10n('home.description') } />
      </PageLayout>
    </Page>
  )
}

export default React.memo(Home)
