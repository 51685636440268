import { each } from 'lodash-es'

import text from 'helpers/text'

export const mqSizes = {}

mqSizes.small = 1300
mqSizes.tablet = 1024
mqSizes.tabletP = 900
mqSizes.phone = 740
mqSizes.phoneP = 420

const mq = {}

each(mqSizes, (value, key) => {
  mq[key] = `@media all and (max-width: ${value}px)`
  mq['not' + text.capitalize(key)] = `@media all and (min-width: ${value + 1}px)`
})

mq.smallPhone = '@media all and (max-height: 560px)'

mq.hover = '@media (hover: hover)'
mq.touch = '@media (hover: none)'

export default mq
