import React, { useCallback, useEffect, useMemo } from 'react'

import { motion, useTransform, useViewportScroll } from 'framer-motion'
import styled from '@emotion/styled'

import scroll from 'helpers/scroll'
import l10n from 'core/l10n'
import { pageAnimation2 } from 'core/animation'
import { mq, rem, remMobile } from 'styles/helpers'
import { useSize } from 'helpers/resize'
import FileButton from 'components/file-button/FileButton'
import CloseButton from 'components/close-button/CloseButton'
import tc from 'helpers/tc'

const Page = styled(motion.section)`
  /* padding: ${rem(250)} 25vw ${rem(250)} 37vw; */
  min-height: 100vh;
`

const Episode = styled.div`
  &:not(:first-of-type) {
    margin-top: ${rem(250)};
  }

  ${mq.tabletP} {
    &:not(:first-of-type) {
      margin-top: ${rem(75)};
    }

    display: flex;
    flex-direction: column;
  }
`

const Inner = styled.div`
  ${mq.notTabletP} {
    padding: ${rem(250)} 0;
    width: ${rem(650)};
    margin-left: ${rem(-325)};
    left: 55%;
    position: relative;
  }

  ${mq.tabletP} {
    padding: ${rem(100)} ${remMobile(50)};
  }
`

const Title = styled.h2`
  font-size: ${rem(90)};
  font-weight: bold;
  line-height: .89;
  margin-bottom: ${rem(70)};

  ${mq.tabletP} {
    font-size: ${rem(35)};
    margin-bottom: ${rem(30)};
  }
`

const Arrow = styled(motion.button)`
  width: ${rem(35)};
  height: ${rem(50)};
  position: fixed;
  bottom: ${rem(44)};
  right: ${rem(44)};
  background-image: url('/assets/svg/scroll.svg');
`

const anim = pageAnimation2()

const Advices = () => {
  const { scrollYProgress } = useViewportScroll()
  const [width, height] = useSize() //eslint-disable-line
  const { scrollHeight } = document.scrollingElement
  const scrollLimit = useMemo(() => 100 / scrollHeight, [scrollHeight, height])

  const opacity = useTransform(scrollYProgress, [1 - scrollLimit, 1], [1, 0])

  const onArrowClick = useCallback(() => {
    const { scrollHeight, scrollTop } = document.scrollingElement
    const to = Math.min(scrollHeight - height, scrollTop + height)
    scroll.to(to)
    tc.click('suivant')
  }, [height])

  const onAdviceClick = (episode, file) => () => {
    const type = ~file.name.toLowerCase().indexOf('mineur') ? 'fiche_mineur' : ~file.name.toLowerCase().indexOf('parent') ? 'fiche_parent' : 'fiche'
    tc.click(type)
    tc.page({ page: tc.episode(episode.id), page_chapter1: 'nos_conseils_de_prevention' })
    tc.page({ page: type, page_chapter1: 'nos_conseils_de_prevention', page_chapter2: tc.episode(episode.id) })
  }

  useEffect(() => { tc.page({ page: 'nos_conseils_de_prevention' }) }, [])

  return (
    <Page { ...anim }>
      <CloseButton />
      <Inner>
        {l10n('episodes').map(episode => (
          <Episode key={ episode.id }>
            <Title>{episode.subject}</Title>
            {episode.result.files.map((file, k) => (
              <FileButton onClick={ onAdviceClick(episode, file) } key={ k } file={ file.src } text={ file.name } number={ k } link={ file.link && { src: file.link, name: file.linkName } } />
            ))}
          </Episode>
        ))}
        <Arrow style={ { opacity } } onClick={ onArrowClick } />
      </Inner>
    </Page>
  )
}

export default React.memo(Advices)
