import React, { useCallback, useMemo } from 'react'

import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { css } from '@emotion/react'

import { rem, colors, snippets, mq, easing, remMobile, sizes } from 'styles/helpers'
import l10n from 'core/l10n'
import { connect } from 'helpers/state'
import persistentStore from 'stores/persistentStore'
import tc from 'helpers/tc'

const duration = .3
const ease = easing.swift
const offsetY = 50

const Block = styled.span`
  width: ${rem(180)};
  height: ${rem(260)};
  border-radius: ${rem(40)};
  margin-bottom: ${rem(20)};
  padding: 0 ${rem(20)};
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  z-index: 1;

  ${mq.tabletP} {
    width: 100%;
    height: ${sizes.episodeButtonMobile.height}vw;
    border-radius: ${sizes.episodeButtonMobile.width / 6}vw;
    margin-bottom: ${remMobile(40)};
  }

  &::before {
    content: '';
    ${snippets.fullscreen}
    display: block;
    z-index: -1;
    background: url(${p => p.background});
    background-size: cover;
    background-position: center;

    ${p => p.retry && css`
      filter: saturate(0);
      opacity: .35;
    `}
  }
`

const Label = styled.span`
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: ${colors.white};
  position: relative;
  z-index: 1;
  ${snippets.transition('transform, opacity', duration, ease)}
  opacity: 0;
  text-align: center;
  transform: translateY(${rem(offsetY)});

  ${p => p.retry && css`
    transform: none;
    opacity: 1;
  `}
`

const Play = styled.span`
  ${snippets.transition('transform, opacity', duration, ease)}
  display: block;
  width: ${rem(52)};
  height: ${rem(52)};
  margin-left: ${rem(-26)};
  margin-top: ${rem(-26)};
  position: absolute;
  background-image: url('assets/svg/play.svg');
  background-size: contain;
  z-index: 3;
  top: 50%;
  left: 50%;

  ${mq.tabletP} {
    width: ${remMobile(90)};
    height: ${remMobile(90)};
    margin-left: ${remMobile(-45)};
    margin-top: ${remMobile(-45)};
  }
`

const Footer = styled.span`
  display: block;
  text-align: left;
  color: ${colors.grey};
  ${snippets.transition('color', duration, ease)}

  ${p => p.retry && css`
    opacity: .5;
  `}
`

const Subject = styled.span`
  display: block;
  font-weight: bold;
  white-space: nowrap;
`
const Name = styled(Subject)`
  text-transform: uppercase;
  font-weight: normal;
`

const Overlay = styled.span`
  display: block;
  ${snippets.fullscreen}
  ${snippets.transition('box-shadow, background', duration, ease)}
  background-color: rgba(0, 0, 0, .3);
  border-radius: inherit;
  box-sizing: border-box;

  ${p => p.retry && css`
    background-color: rgba(0, 0, 0, .1) !important;
  `}
`

const hoverState = css`
  ${Overlay} {
    box-shadow: inset 0 0 0 ${rem(6)} ${colors.green};
    background-color: rgba(0, 0, 0, .4);

    ${mq.tabletP} {
      box-shadow: inset 0 0 0 ${remMobile(10)} ${colors.green};
    }
  }

  ${Label} {
    opacity: 1;
    transform: none;
  }

  ${Footer} {
    color: ${colors.black};
  }

  ${Play} {
    opacity: 0;
    transform: translateY(${rem(-offsetY)});
  }
`

const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-size: ${rem(17)};
  line-height: 1.26;

  ${mq.tabletP} {
    width: ${sizes.episodeButtonMobile.width}vw;
    font-size: ${remMobile(27)};
  }

  ${p => p.selected && hoverState}
`

const EpisodeButton = ({ episode, mqTouch, episodeRolled, updateStoreValue, className, ...props }) => {
  const state = useMemo(() => {
    const status = persistentStore[episode.id].get()
    return { retry: status === -1, resume: status > 0 }
  }, [episode.id])

  const onMouseEnter = useCallback(() => {
    updateStoreValue('episodeRolled', episode.id)
  }, [])

  const onMouseLeave = useCallback(() => {
    if (episodeRolled === episode.id) updateStoreValue('episodeRolled', null)
  }, [episodeRolled])

  const onClick = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    if (episodeRolled === episode.id) onMouseLeave()
    else onMouseEnter()
    tc.click(episode.name.toLowerCase().replace('é', 'e'))
  }, [episodeRolled])

  return (
    <Button to={ `/episode/${episode.id}` } selected={ episodeRolled === episode.id } { ...(!mqTouch ? { onMouseEnter, onMouseLeave, className } : { onClick, className }) }>
      <Block { ...state } background={ `assets/images/thumbs/${episode.id}.jpg` }>
        {(!state.retry) && (<Play />)}
        <Overlay { ...state } />
        {!mqTouch && (
          <Label { ...state }>
            {l10n(state.retry ? 'common.restart' : state.resume ? 'common.resume' : 'common.start')}
          </Label>
        )}
      </Block>
      <Footer { ...state }>
        <Name>{episode.name}</Name>
        <Subject>{episode.subject}</Subject>
      </Footer>
    </Button>
  )
}

export default connect('episodeRolled, mqTouch')(React.memo(EpisodeButton))
