
import { bezier } from 'styles/helpers'
import detect from 'helpers/detect'

const base = {
  animate: 'animate',
  exit: 'exit',
  initial: 'initial'
}

// const ease = bezier.principleOut
const invert = (v, invert) => v * (invert ? -1 : 1)
// const axe = (vertical) => vertical ? 'y' : 'x'

const willChange = (props) => (detect.safari ? {} : {
  willChange: 'transform, opacity', transitionEnd: { willChange: null }
})

const staggerChildren = (staggerChildren = 0.05, delayChildren = 0.3) => Object.assign({
  variants: {
    animate: {
      transition: { staggerChildren, delayChildren, staggerDirection: -1 }
    }
  }
}, base)

const pageAnimation = () => staggerChildren()
const pageAnimation2 = ({ distance = -40 } = {}) => {
  return {
    variants: {
      initial: { opacity: 0, y: -distance },
      exit: {
        opacity: 0,
        y: distance,
        ...willChange('opacity, transform'),
        transition: {
          duration: 0.5, ease: bezier.swiftOut
        }
      },
      animate: {
        opacity: 1,
        ...willChange('opacity, transform'),
        y: 0,
        transition: {
          duration: 0.5, ease: bezier.swiftOut
        }
      }
    }
  }
}

const itemAnimation = ({ distance = -10 } = {}) => {
  return {
    variants: {
      initial: { opacity: 0, y: -distance },
      exit: {
        opacity: 0,
        y: distance,
        ...willChange('opacity, transform'),
        transition: {
          duration: 0.3, ease: bezier.swiftOut2, delay: 0.1
        }
      },
      animate: {
        opacity: 1,
        ...willChange('opacity, transform'),
        y: 0,
        transition: {
          duration: 0.6, ease: bezier.swiftOut2
        }
      }
    }
  }
}

const messageAnimation = ({ rotate = 40, offset = 30, scale = 0.5 } = {}) => {
  return {
    transformTemplate: ({ rotateX, scale }) => {
      return `translateZ(${-offset}px) rotateZ(${rotateX}) translateZ(${offset}px) scale(${scale})`
    },
    variants: {
      initial: { opacity: 0, rotateX: -rotate, scale },
      exit: {
        opacity: 0,
        rotateX: rotate,
        scale: scale,
        ...willChange('opacity, transform'),
        transition: {
          duration: 0.2, ease: bezier.principleIn
        }
      },
      animate: {
        opacity: 1,
        scale: 1,
        ...willChange('opacity, transform'),
        rotateX: 0,
        transition: {
          duration: 0.2, ease: bezier.principleOut
        }
      }
    }
  }
}

const fadeAnimation = ({ easeIn = bezier.quadEaseOut, easeOut = bezier.quadEaseOut, duration = .3 } = {}) => {
  const _duration = duration

  return {
    variants: {
      initial: { opacity: 0.01 },
      exit: ({ duration } = {}) => ({
        opacity: 0,
        // ...willChange('opacity'),
        transition: {
          duration: duration || _duration, ease: easeOut
        }
      }),
      animate: {
        opacity: 1,
        // ...willChange('opacity'),
        transition: {
          duration: duration, ease: easeIn
        }
      }
    }
  }
}

const choiceAnimation = () => {
  return {
    variants: {
      initial: ({ inverted = false } = {}) => ({
        y: (invert(100, inverted) + '%'),
        opacity: 0
      }),
      exit: ({ inverted = false } = {}) => ({
        y: (invert(-100, inverted) + '%'),
        opacity: 0,
        ...willChange('transform, opacity'),
        transition: {
          duration: 0.6, ease: bezier.swiftOut
        }
      }),
      animate: {
        y: 0,
        opacity: 1,
        ...willChange('transform, opacity'),
        transition: {
          duration: 0.6, ease: bezier.swiftOut
        }
      }
    }
  }
}

const translate = ({ direction = 'y', initial = '100%', exit = '100%', animate = 0, duration = .4 } = {}) => {
  return Object.assign({
    variants: {
      initial: { [direction]: initial },
      exit: {
        [direction]: exit,
        ...willChange('transform'),
        transition: {
          duration, ease: bezier.swiftOut
        }
      },
      animate: {
        [direction]: animate,
        ...willChange('transform'),
        transition: {
          duration, ease: bezier.swiftOut
        }
      }
    }
  }, base)
}

const cookieAnimation = () => translate()

const menuAnimation = () => translate({ initial: '-100%', exit: '-100%', direction: 'x', duration: .5 })

const popinAnimation = () => translate({ initial: '-100%', exit: '-100%', duration: .5 })

const episodesAnimation = () => translate({ initial: '120%', exit: '120%', duration: .5 })

const backgroundAnimation = () => {
  return {
    transformTemplate: ({ y }) => {
      return `translateY(${y}) translate(-50%, -50%)`
    },
    variants: {
      initial: {
        y: '150vh'
      },
      exit: {
        y: '-150vh',
        ...willChange('opacity, transform'),
        transition: {
          duration: 0.9, ease: bezier.backEaseIn
        }
      },
      animate: {
        ...willChange('opacity, transform'),
        y: 0,
        transition: {
          duration: 0.9, ease: bezier.backEaseOut
        }
      }
    }
  }
}

export {
  pageAnimation,
  pageAnimation2,
  itemAnimation,
  staggerChildren,
  messageAnimation,
  menuAnimation,
  fadeAnimation,
  choiceAnimation,
  backgroundAnimation,
  cookieAnimation,
  episodesAnimation,
  popinAnimation,
  base as baseAnimation
}
