import { css } from '@emotion/react'

import { fonts } from '../helpers/variables'

export default css`
  @font-face {
    font-family: ${fonts.mainFontname};
    src: url('/assets/fonts/Gotham-Book.woff2') format('woff2'), url('/assets/fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: ${fonts.mainFontname};
    src: url('/assets/fonts/Gotham-Bold.woff2') format('woff2'), url('/assets/fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
  }

  /* @font-face {
    font-family: ${fonts.alternativeFontname};
    src: url('/assets/fonts/HydrophiliaIced.woff2') format('woff2'), url('/assets/fonts/HydrophiliaIced.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
  } */
  @font-face {
    font-family: ${fonts.alternativeFontname};
    src: url('https://use.typekit.net/af/9c597c/00000000000000003b9afa1f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2'), url('https://use.typekit.net/af/9c597c/00000000000000003b9afa1f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff'), url('https://use.typekit.net/af/9c597c/00000000000000003b9afa1f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: bold;
  }
`
