/* global tc_events_20 */

// import tarteaucitron from 'vendors/tarteaucitron/tarteaucitron'

window.dataLayer = window.dataLayer || []

const DEBUG = false

const trigger = (method, params) => {
  try {
    tc_events_20 && tc_events_20(this, method, params)
  } catch (error) {

  }

  if (DEBUG) {
    console.log(// eslint-disable-line
      `%c TAG: ${method === 'load' ? params.page : method} `,
      `background: #000; color: #${method === 'load' ? 'bada55' : 'EB3D54'}; font-weight: bold`,
      params
    )
  }
}

const _episodes = {
  'ecrans-et-dependance': 'ecrans_et_dependance',
  cyberharcelement: 'cyber_harcelement',
  'protection-des-donnees': 'protection_des_donnees'
}

const episode = (episode) => {
  return _episodes[episode]
}

const page = (params) => {
  return trigger('load', { page_chapter1: '', page_chapter2: '', page_chapter3: '', ...params })
}

const click = (param) => {
  return trigger('click', { evt_click_name: param })
}

const tc = { trigger, episode, page, click }

export default tc
