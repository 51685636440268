import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'

import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import { css } from '@emotion/react'

import { colors, easing, mq, rem, snippets } from 'styles/helpers'
import { connect } from 'helpers/state'
import CloseButton from 'components/close-button/CloseButton'
import { baseAnimation, popinAnimation } from 'core/animation'

const Wrapper = styled(motion.div)`
  ${snippets.fixed('top, left, bottom, right')}
  z-index: 90;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Inner = styled(motion.div)`
  width: ${rem(700)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backface-visibility: hidden;
  background: ${colors.green} ;
  border: ${rem(5)} solid ${colors.white};
  padding: ${rem(50)} ${rem(50)};


  ${mq.tabletP} {
    width: 95%;
    padding: ${rem(20)} ${rem(20)};
  }
`
const Overlay = styled(motion.div)`
  ${snippets.fixed('top, left, bottom, right')}
  z-index: -1;
  cursor: pointer;
`

const Title = styled.h3`
  font-size: ${rem(32)};
  margin-bottom: ${rem(30)};
  font-weight: bold;
  line-height: 1.11;

  ${mq.tabletP} {
    font-size: ${rem(20)};
    margin-bottom: ${rem(10)};
  }
`
const Notes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${rem(45)};
  max-width: ${rem(400)};

  ${mq.tabletP} {
    margin-top: ${rem(25)};
    max-width: ${rem(300)};
  }
`

const Note = styled.button`
  display: block;
  height: ${rem(50)};
  width: ${rem(50)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  color: ${colors.green};
  font-size: ${rem(22)};
  white-space: nowrap;
  margin-right: ${rem(8)};
  transition: color .3s ${easing.swiftOut}, background .3s ${easing.swiftOut};

  &:not(:last-child) {
    margin-bottom: ${rem(8)};
  }

  ${mq.hover} {

    &:hover {
      background-color: ${colors.green2};
      color: ${colors.white};
    }
  }

  ${mq.tabletP} {
    flex: 0 0 ${rem(45)};
    height: ${rem(45)};
    padding: 0 ${rem(15)};
    font-size: ${rem(20)};
    margin-right: ${rem(4)};

    &:not(:last-child) {
      margin-bottom: ${rem(4)};
    }
  }

  ${p => p.clicked && css`
    background: ${colors.green2};
    color: ${colors.white};
  `}
`

const Text = styled.div`
  font-size: ${rem(28)};
  text-align: center;

  ${mq.tabletP} {
    font-size: ${rem(20)};
  }
`

const Button = styled.a`
  color: ${colors.white};
  font-size: ${rem(20)};
  margin-top: ${rem(50)};
  transform: none;
  text-decoration: underline;

  ${mq.tabletP} {
    transform: none;
    font-size: ${rem(16)};
    margin-top: ${rem(30)};
  }
`

const Close = styled(CloseButton)`
  position: relative;
  top: 0;
  left: 0;
  margin-bottom: ${rem(70)};
  margin-left: 0;

  ${mq.tabletP} {
    margin-left: 0;
    top: 0;
    margin-bottom: ${rem(30)};
  }
`

const anim = popinAnimation().variants

const RatePopin = ({ updateStoreValue }, ref) => {
  const [opened, setOpened] = useState(false)
  const [rate, setRate] = useState(-1)

  useImperativeHandle(ref, () => ({
    open: () => setOpened(true),
    close: () => setOpened(false)
  }))

  useEffect(() => {
    const alreadyDone = localStorage.getItem('note')
    if (!alreadyDone) setOpened(true)
  }, [])

  const onToken = (token) => {
    const xhr = new XMLHttpRequest()
    const params = `a=${rate}&token=${token}`
    xhr.open('POST', '/api.php')
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    xhr.send(params)
  }

  useEffect(() => {
    if (rate < 0) return
    const xhr = new XMLHttpRequest()
    xhr.open('POST', '/api.php')
    xhr.onload = () => onToken(xhr.responseText)
    xhr.send()
    localStorage.setItem('note', rate)
    setOpened(false)
  }, [rate])

  return (
    <AnimatePresence exitBeforeEnter>
      {opened && (
        <Wrapper { ...baseAnimation } variants={ anim }>
          <Inner>
            <Close onClick={ () => setOpened(false) } />
            <Title>Nous avons besoin de vous !</Title>
            <Text>
              Sur une échelle de 0 à 10 dans quelle
              mesure recommanderiez-vous cette
              expérience à vos proches ?
            </Text>
            <Notes>
              {
                Array(11).fill(1).map((_, i) => (
                  <Note clicked={ i === rate } key={ i } onClick={ () => setRate(i) }>{ i }</Note>
                ))
              }
            </Notes>
            <Button onClick={ () => setOpened(false) }>Je réponds plus tard</Button>
          </Inner>
          <Overlay onClick={ () => setOpened(false) } />
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

export default connect('')(React.memo(forwardRef(RatePopin)))
