import React, { Children, PureComponent, createElement, createRef, forwardRef } from 'react'
import PropTypes from 'prop-types'

import Emitter from 'tiny-emitter'
import { debounce, mapValues, pick, each } from 'lodash-es'

// Emitter

export class StoreEmitter {
  constructor (value, name) {
    this.emitter = new Emitter()
    this.value = value
    this.name = name
  }

  listenAndStart (callback, context) {
    this.listen(callback, context)
    this.emitter.emit('', this.value, this.value)
  }

  listen (callback, context) { this.emitter.on('', callback, context) }
  listenOnce (callback, context) { this.emitter.once('', callback, context) }
  unlisten (callback) { this.emitter.off('', callback) }
  destroy () { this.unlisten() }

  set (value, force) {
    if (!force && this.value === value) return
    const previous = this.value
    this.value = value
    this.emitter.emit('', value, previous)
  }

  get () { return this.value }
}

// createStore

export function createStore (state) {
  let emitters = {}
  for (const k in state) emitters[k] = new StoreEmitter(state[k], k)
  emitters = Object.freeze(emitters)
  return emitters
}
export function exportStore (store) {
  return mapValues(store, (v) => v.get())
}

export default createStore

export function mapActions (actions, store) {
  return mapValues(actions, (action, key) => {
    return function () {
      const flatStore = exportStore(store)
      const updateValue = (key, value) => store[key] && store[key].set(value)
      // const updateValue = (value, key) => store[key] && store[key].set(value)
      flatStore.updateStoreValue = updateValue

      return Promise.resolve()
        .then(() => action(flatStore, ...arguments))
        .then((result) => {
          result && each(result, (value, key) => store[key] && store[key].set(value, !!result.force))
          return result
        })
    }
  })
}

function updateStore (state, object, force = false) { return { ...object, force } }
function updateStoreValue (state, key, value, force = false) { return { [key]: value, force } }

export function connect (mapStateToProps, actions = {}) {
  const storeKeys = mapStateToProps ? mapStateToProps.split(/\s*,\s*/) : []

  return Target => {
    let Wrapper = function (props, context) {
      PureComponent.call(this, props, context)
      const store = context.store || {}
      const emitters = pick(store, storeKeys)
      this.state = exportStore(emitters)

      if (!actions.updateStore) actions.updateStore = updateStore
      if (!actions.updateStoreValue) actions.updateStoreValue = updateStoreValue
      const mappedActions = mapActions(actions, store)

      const updateDebounced = debounce(() => {
        update()
      }, 1)

      const update = (value) => {
        let updated = false

        each(emitters, (emitter, key) => {
          if (!emitter) return

          if (this.state[key] !== emitter.get()) {
            this.state[key] = emitter.get()
            updated = true
          }
        })

        if (updated && this._isMounted) this.forceUpdate()
      }

      this.instance = createRef()

      // TODO getDerivedStateFromProps ?
      this.UNSAFE_componentWillReceiveProps = p => {
        props = p
        updateDebounced()
        each(emitters, (emitter) => emitter && emitter.listen(updateDebounced))
      }

      this.componentDidMount = () => {
        updateDebounced()
        this._isMounted = true
        each(emitters, (emitter) => emitter && emitter.listen(updateDebounced))
      }
      this.componentWillUnmount = () => {
        this._isMounted = false
        each(emitters, (emitter) => emitter && emitter.unlisten(updateDebounced))
      }

      this.render = () => {
        const { forwardedRef, ...rest } = this.props
        return createElement(Target, Object.assign({ ...rest, ref: forwardedRef }, mappedActions, this.state))
      }
    }

    Wrapper.contextTypes = { store: PropTypes.object }
    Wrapper = ((Wrapper.prototype = Object.create(PureComponent.prototype)).constructor = Wrapper)
    const RefWrapper = forwardRef((props, ref) => (<Wrapper { ...props } forwardedRef={ ref } />))

    if (Target.getInitialProps) RefWrapper.getInitialProps = Target.getInitialProps
    RefWrapper.Ref = Target

    return RefWrapper
  }
}

export class Provider extends PureComponent {
  getChildContext () {
    if (this.context && this.context.store) {
      return {
        store: Object.assign({}, this.context.store, this.props.store)
      }
    }

    return { store: this.props.store }
  }

  render () {
    return Children.only(this.props.children)
  }
}

Provider.propTypes = { store: PropTypes.object, children: PropTypes.any }
Provider.contextTypes = Provider.childContextTypes = { store: PropTypes.object }
