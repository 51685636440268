import React, { useMemo } from 'react'

import { motion } from 'framer-motion'
import styled from '@emotion/styled'
import { find } from 'lodash-es'
import { useHistory } from 'react-router-dom'

import { fadeAnimation } from 'core/animation'
import { colors, snippets } from 'styles/helpers'
import { Provider } from 'helpers/state'
import episodeStore from 'stores/episodeStore'
import l10n from 'core/l10n'

import EpisodeTimeline from './components/EpisodeTimeline'
import EpisodeChoices from './components/EpisodeChoices'
import EpisodePlayer from './components/EpisodePlayer'

const Page = styled(motion.section)`
  ${snippets.absolute('top, left, right, bottom')}
  background: ${colors.black};
  overflow: hidden;
  z-index: 15;
`

const data = {
  'ecrans-et-dependance': require('data/episodes/ecrans-et-dependance.yml'),
  'protection-des-donnees': require('data/episodes/protection-des-donnees.yml'),
  cyberharcelement: require('data/episodes/cyberharcelement.yml')
}

const fade = fadeAnimation({ duration: .4 })

const Episode = ({ match, ...props }) => {
  const { episodeId } = match.params
  const episode = useMemo(() => Object.assign({}, find(l10n('episodes'), ['id', episodeId]), data[episodeId]), [episodeId])
  const store = useMemo(() => episodeStore({ episode }), [episode])
  const history = useHistory()

  store.end.listen((end) => {
    if (end) {
      const choice = store.choiceA.get() > store.choiceB.get() ? 'a' : 'b'
      setTimeout(() => history.push(`/episode-end/${episodeId}/${choice}`), 10)
    }
  })

  return (
    <Page { ...fade }>
      <Provider store={ store }>
        <>
          <EpisodePlayer />
          <EpisodeChoices />
          <EpisodeTimeline />
        </>
      </Provider>
    </Page>
  )
}

export default React.memo(Episode)
