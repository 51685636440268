import React, { useMemo } from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { AnimatePresence, motion, useIsPresent } from 'framer-motion'

import { colors, easing, mq, rem, snippets } from 'styles/helpers'
import { connect } from 'helpers/state'
import { cookieAnimation } from 'core/animation'

const Wrapper = styled(motion.ul)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${rem(35)};
  display: flex;
  pointer-events: none;

  ${mq.tabletP} {
    padding: ${rem(15)};
  }
`
const Line = styled.li`
  background: rgba(255, 255, 255, .6);
  height: ${rem(10)};
  border-radius: ${rem(4)};
  flex-grow: 1;
  position: relative;

  ${mq.tabletP} {
    height: ${rem(6)};
    border-radius: ${rem(3)};
  }

  &:not(:first-of-type) {
    margin-left: ${rem(35)};

    ${mq.tabletP} {
      margin-left: ${rem(15)};
    }
  }
`
const InnerLine = styled.span`
  display: block;
  border-radius: inherit;
  height: 100%;
  background-color: ${colors.green};
  width: ${p => p.progress * 100}%;
`
const Time = styled.span`
  color: ${colors.green};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: ${rem(32)};
  font-weight: bold;
  padding-bottom: ${rem(18)};
  text-align: center;
  ${snippets.transition('transform, opacity', .3, easing.swiftOut)}
  ${mq.tabletP} {
    font-size: ${rem(20)};
    padding-bottom: ${rem(10)};
  }

  ${p => p.visible
  ? css`
    transform: translateY(-100%);
  `
  : css`
    opacity: 0;
  `}
`

const animation = cookieAnimation()

const EpisodeTimeline = ({ episode, sequence, time, duration, started, previousDuration, previousOffset, offset }) => {
  const isOffset = useMemo(() => time < offset, [time, offset])

  const virtualSequence = useMemo(() => {
    if (isOffset) return Math.max(0, sequence - 1)
    else return sequence
  }, [isOffset, sequence])

  const virtualTime = useMemo(() => {
    if (isOffset) return (previousDuration - previousOffset) + time
    return time - offset
  }, [isOffset, time, offset, previousOffset, previousDuration])

  const tempOffset = useMemo(() => {
    const seq = episode.sequences[sequence]
    if (!seq) return 0
    return (seq.choiceAOffset + seq.choiceBOffset) / 2
  }, [sequence])

  const virtualDuration = useMemo(() => {
    if (isOffset) return (previousDuration - previousOffset) + offset
    return (duration - offset) + tempOffset
  }, [isOffset, duration, previousDuration, previousOffset, offset, tempOffset])

  const progress = useMemo(() => (virtualTime / virtualDuration), [virtualTime, virtualDuration])
  const isPresent = useIsPresent()

  return (
    <AnimatePresence>
      {started && isPresent && (
        <Wrapper { ...animation }>
          {episode.sequences.map((e, k) => (
            <Line key={ k }>
              <InnerLine progress={ k === virtualSequence ? progress : k > virtualSequence ? 0 : 1 } />
              <Time visible={ k === virtualSequence }>{e.hour}</Time>
            </Line>
          ))}
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

export default connect('episode, sequence, time, duration, started, previousDuration, previousOffset, offset')(React.memo(EpisodeTimeline))
