import React from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { rem, colors, snippets, mq, easing, remMobile } from 'styles/helpers'

const withChildren = p => (p.haschildren && css`
  padding-right: ${rem(20)};

  ${mq.tabletP} {
    justify-content: space-between;
    padding-right: ${remMobile(20)};
  }
`)

const Text = styled.span`
`

const Inner = styled.span`
  display: inline;
  flex-shrink: 1;
  flex-grow: 0;
  position: relative;

  sup {
    font-size: .45em;
  }
`

const Hover = styled(Inner)`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
`

const Wrapper = styled.a`
  border-radius: ${rem(55)};
  min-width: ${rem(388)};
  height: ${rem(110)};
  padding: ${rem(25)} ${rem(40)};
  border: solid ${rem(5)} ${colors.white};
  font-size: ${rem(32)};
  font-weight: bold;
  line-height: 1.11;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: min-content;

  & + & {
    margin-top: ${rem(35)};
  }

  ${mq.tabletP} {
    border-radius: ${remMobile(70)};
    min-width: ${remMobile(500)};
    height: ${remMobile(140)};
    padding: ${remMobile(32)} ${remMobile(50)};
    border: solid ${remMobile(6)} ${colors.white};
    font-size: ${remMobile(42)};

    & + & {
      margin-top: ${remMobile(35)};
    }
  }

  ${mq.hover} {
    ${snippets.transition('color, background-color, border-color, opacity', .3, easing.swift)}

    path, circle {
      ${snippets.transition('fill, stroke', .3, easing.swift)}
    }

    ${Text}, ${Hover} {
      ${snippets.transition('opacity', .3, easing.swift)}
    }

    &:hover {
      background-color: ${colors.white};
      color: ${colors.green};

      ${p => p.hover && css`
      ${Text} {
        opacity: 0;
      }

      ${Hover} {
        opacity: 1;
      }
  `}
    }
  }
  ${withChildren}
`

const Button = ({ children, text, hover, ...props }) => {
  return (
    <Wrapper { ...props } hover={ !!hover } haschildren={ children ? 1 : 0 }>
      <Inner>
        <Text dangerouslySetInnerHTML={ { __html: text } } />
        { hover && <Hover dangerouslySetInnerHTML={ { __html: hover } } />}
      </Inner>
      {children}
    </Wrapper>
  )
}

export default React.memo(Button)
