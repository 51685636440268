import { get, memoize, reduceRight, sortBy } from 'lodash-es'

import translations from 'loc/fr.json'

translations.episodes = sortBy(translations.episodes, (episode) => {
  if (episode.name.toLowerCase() === 'lucas') return 0
  if (episode.name.toLowerCase() === 'catherine') return 1
  return 2
})

const l10n = memoize((key, params = false, noFallback = false) => {
  if (key === '@') return ''
  let text = get(translations, key, noFallback === false ? `@missing-key:${key}` : key)
  if (params) text = reduceRight(params, (text, param, key) => text.replace(new RegExp(`%${key}%`, 'gi'), param), text)
  return text
}, (key, params) => {
  if (!params) return `${key}--${translations}`
  else return `${key}--${JSON.stringify(params)}--${translations}`
})

export default l10n
