import React, { useEffect } from 'react'

import { pageAnimation2 } from 'core/animation'
import { LegalPage as Page, LegalParagraph as Paragraph, LegalTitle as Title } from 'sections/legals/Legals'
import { PrivacySubtitle as Subtitle, PrivacySmalltitle as Smalltitle, PrivacyMinititle as Minititle } from 'sections/privacy/Privacy'
import CloseButton from 'components/close-button/CloseButton'
import tc from 'helpers/tc'

const anim = pageAnimation2()

const CookiePolicy = () => {
  useEffect(() => { tc.page({ page: 'politique_des_cookies' }) }, [])

  return (
    <Page { ...anim }>
      <CloseButton />

      <Title> POLITIQUE DES COOKIES </Title>
      <Paragraph> POLITIQUE DES COOKIESLors de la consultation de notre site internet, des informations relatives à votre navigation sont susceptibles d'être enregistrées dans des fichiers "Cookies" installés sur votre terminal (ordinateur, tablette, smartphone...),sous réserve de vos choix exprimés concernant les cookies et que vous pouvez modifier à tout moment.Les informations ci-dessous vous permettent de mieux comprendre le fonctionnement des cookies et l'utilisation des outils actuels afin de les paramétrer. </Paragraph>

      <Subtitle>QU'EST-CE QU'UN COOKIE ?</Subtitle>
      <Paragraph> Un cookie est un petit fichier texte, image ou logiciel, contenant des informations qui est enregistré sur le disque dur de votre terminal (ex: ordinateur, tablette, smartphone, ou tout autre appareil permettant de naviguer sur internet) à l’occasion de la consultation d’un site internet. Il est transmis par le serveur d’un site Internet à votre navigateur. Le fichier cookie porte notamment sur les pages visitées, les publicités sur lesquelles vous avez cliquées, le type de navigateur que vous utilisez, votre adresse IP, et permet à son émetteur d’identifier le terminal dans lequel il est enregistré, pendant la durée de validité du cookie concerné. </Paragraph>
      <Paragraph> Comme indiqué sur le bandeau d’information du site, seuls les cookies acceptés lors de votre première navigation sur le site sont susceptibles d’être déposés sur votre terminal. Vous pouvez à tout moment revenir sur cette décision en vous opposant au dépôt des cookies (voir ci-dessous: comment gérer les cookies) </Paragraph>

      <Subtitle>A QUOI SERT UN COOKIE ?</Subtitle>
      <Paragraph> Les cookies ont plusieurs fonctions. </Paragraph>
      <Paragraph> Ils facilitent notamment votre navigation sur notre site internet et améliorent considérablement sa convivialité. Les cookies, sous réserve de vos choix exprimés concernant les cookies et que vous pouvez modifier à tout moment, peuvent vérifier si votre terminal de navigation (ordinateur, tablette, smartphone...) a déjà été connecté à notre site et de conserver certaines informations de votre passage. Ces informations permettront d’améliorer votre navigation lors de la visite suivante. </Paragraph>
      <Paragraph> Certains cookies techniques sont indispensables pour le bon fonctionnement du site internet. Leur suppression peut entraîner des difficultés de navigation ainsi que l’impossibilité d’utiliser certains servicesproposés par notre site Internet. </Paragraph>

      <Subtitle>QUELS SONT LES COOKIES UTILISÉS SUR NOTRE SITE ?</Subtitle>
      <Paragraph> Notre site Internet utilise différentes catégories de cookies. Certains cookies techniques (cookies de session notamment) sont strictement nécessaires au bon fonctionnement de notre site internet. Leur suppression peut entraîner des difficultés de navigation. Les cookies sont conservés pendant une durée n’excédant pas 13 mois, sauf suppression préalable de votre part (voir ci-dessous: comment gérer les cookies?). </Paragraph>

      <Subtitle>LES COOKIES TECHNIQUES ET FONCTIONNELS</Subtitle>
      <Paragraph> Ces cookies permettent d’accéder, grâce à vos identifiants personnels (cookie identifiant de session), à certains espaces réservés, de mettre en œuvre des mesures de sécurité (lorsqu’il vous est par exemple demandé de vous identifier à nouveau après un certain délai d’inactivité), de permettre ou de faciliter la communication en vue de la fourniture des services proposés par notre site (accès à vos espaces personnels notamment). </Paragraph>
      <Paragraph> Ces cookies sont indispensables et strictement nécessaires pour vous permettre de naviguer sur notre site et d’utiliser ses fonctionnalités. Ils nous permettent également d’adapter la présentation de notre site aux préférences d’affichage de votre terminal. </Paragraph>
      <Paragraph> Ces cookies peuvent être paramétrés au niveau du navigateur. Néanmoins, si vous choisissez de désactiver les cookies au niveau du navigateur, notre site peut fonctionner différemment. </Paragraph>
      <Paragraph> Certains cookies sont également nécessaires à l’enregistrement et au respect de préférences et de vos options notamment concernant le refus d’ajouter d’autres types de cookies sur votre appareil. Si vous supprimez les cookies au niveau du navigateur, celui utilisé pour reconnaître vos options sera également supprimé, vous obligeant à sélectionner de nouveau vos préférences. </Paragraph>
      <Paragraph> Ces cookies sont émis et déposés par PACIFICA. Ils ont une durée de vie très courte, pour la majorité, le temps de la cession, et au maximum une année. </Paragraph>

      <Subtitle>LES COOKIES DE PERFORMANCE ET DE MESURE D’AUDIENCE</Subtitle>
      <Paragraph> Ces cookies recueillent des informations sur la façon dont les visiteurs utilisent un site internet (nombre de visites, les pages les plus visitées, les contenus les plus consultés, etc.). Ces cookies permettent d’établir des statistiques d’analyse de la fréquentation  notre site internet et d’en améliorer les fonctionnalités. </Paragraph>
      <Paragraph> XITI (AT Internet) et Google Analytics, outils de statistiques utilisés par PACIFICA, génèrent un cookie avec un identifiant unique, dont la durée de conservation est limitée à 13 mois. Les données recueillies ne sont pas cédées à des tiers ni utilisées à d’autres fins. </Paragraph>

      <Subtitle>COMMENT GÉRER LES COOKIES ?</Subtitle>
      <Paragraph> Plusieurs possibilités vous sont offertes pour gérer les cookies. Vous pouvez faire le choix à tout moment d’exprimer et de modifier vos souhaits en matière de cookies, par les moyens décrits ci-dessous.  </Paragraph>

      <Smalltitle> 1. Paramétrage de votre navigateur </Smalltitle>
      <Paragraph> Vous avez la possibilité de naviguer sur notre site et de vous opposer à l’utilisation des cookies. Pour cela, vous devez sélectionner une option sur votre navigateur telle que « bloquer/interdire les cookies ». Leur suppression peut entraîner des difficultés voire une impossibilité de navigation ainsi que l’impossibilité de souscrire en ligne à nos offres. </Paragraph>
      <Paragraph> Vous avez également la possibilité de supprimer à tout moment les cookies déjà présents dans votre terminal. Néanmoins, tout paramétrage que vous pouvez entreprendre sera susceptible de modifier votre navigation sur Internet et vos conditions d’accès à certains services nécessitant l’utilisation de Cookies. </Paragraph>
      <Paragraph> Vous pouvez configurer votre logiciel de navigation de manière à ce que des cookies soient enregistrés dans votre terminal ou au contraire qu’ils soient rejetés soit systématiquement, soit selon leur émetteur. Vous pouvez également configurer votre logiciel de navigation de manière à ce que l’acceptation ou le refus des cookies vous soient proposés ponctuellement, avant qu’un cookie soit susceptible d’être enregistré dans votre terminal. </Paragraph>
      <Paragraph> La configuration de chaque navigateur est différente. Elle est décrite dans le menu d’Aide de votre navigateur. </Paragraph>

      <Minititle> Pour Chrome </Minititle>
      <Paragraph> <a target='_blank' rel='noreferrer' href='https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en'>https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en</a> </Paragraph>

      <Minititle> Pour Internet Explorer </Minititle>
      <Paragraph> <a target='_blank' rel='noreferrer' href='https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies'>https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies</a> </Paragraph>

      <Minititle> Pour Firefox </Minititle>
      <Paragraph> <a target='_blank' rel='noreferrer' href='https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent'>https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent</a> </Paragraph>

      <Minititle> Pour Safari </Minititle>
      <Paragraph> <a target='_blank' rel='noreferrer' href='https://support.apple.com/fr-fr/guide/safari/sfri11471/mac'>https://support.apple.com/fr-fr/guide/safari/sfri11471/mac</a> </Paragraph>

      <Minititle> Pour Opera </Minititle>
      <Paragraph> <a target='_blank' rel='noreferrer' href='https://help.opera.com/en/latest/web-preferences/#cookies'>https://help.opera.com/en/latest/web-preferences/#cookies</a> </Paragraph>

      <Smalltitle> 2. Vos choix exprimés en ligne directement depuis notre site : les modalités d’opt-out </Smalltitle>
      <Paragraph> Vous pouvez également paramétrer depuis notre site vos choix relatifs aux cookies que nous déposons sur votre terminal, en utilisant cliquant sur le bouton ci-dessous. En cas de refus du cookie, il sera enregistré dans votre terminal un cookie ayant pour unique objet de désactiver les services associés au cookie. </Paragraph>
      <Paragraph> Attention, la prise en compte de votre souhait repose sur un cookie. Si vous supprimez tous les cookies enregistrés dans votre terminal, via votre navigateur, nous ne saurons plus que vous avez choisi cette option. </Paragraph>

    </Page>
  )
}

export default React.memo(CookiePolicy)
