import React, { useCallback } from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'

import { colors, easing, mq, rem, remMobile, snippets } from 'styles/helpers'
import { connect } from 'helpers/state'
import tc from 'helpers/tc'

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: ${rem(30)} ${rem(35)};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 100;
  pointer-events: none;

  > * {
    pointer-events: all;
  }

  ${mq.tabletP} {
    padding: ${remMobile(36)} ${remMobile(50)};
    align-items: center;
  }
`

const Logo = styled(Link)`
  ${snippets.hideText}
  display: block;
  background-image: url('assets/svg/logo.svg');
  background-size: cover;
  width: ${rem(112)};
  height: ${rem(77)};

  ${mq.tabletP} {
    width: ${remMobile(156)};
    height: ${remMobile(108)};
  }
`

const buttonHeight = 8
const buttonHeightM = 12
const ease = easing.swift
const transformDuration = .4
const positionDuration = .25

const ButtonInner = styled.span`
  ${snippets.hideText}
  display: block;
  background-color: ${colors.white};
  width: 100%;
  height: ${rem(buttonHeight)};
  margin-top: ${rem(-buttonHeight / 2)};
  border-radius: ${rem(buttonHeight / 2)};
  position: absolute;
  left: 0;
  top: 50%;
  transform-origin: center;

  ${mq.tabletP} {
    height: ${remMobile(buttonHeightM)};
    margin-top: ${remMobile(-buttonHeightM / 2)};
    border-radius: ${remMobile(buttonHeightM / 2)};
  }

  transition:
    top ${positionDuration}s ${ease} ${transformDuration}s,
    transform ${transformDuration}s ${ease};

  &:nth-last-of-type(1) {
    top: ${rem(buttonHeight / 2)};

    ${mq.tabletP} {
      top: ${remMobile(buttonHeightM / 2)};
    }
  }

  &:nth-last-of-type(3) {
    top: calc(100% - ${rem(buttonHeight / 2)});

    ${mq.tabletP} {
      top: calc(100% - ${remMobile(buttonHeightM / 2)});
    }
  }
`

const Button = styled.button`
  position: relative;
  width: ${rem(53)};
  height: ${rem(42)};

  ${mq.tabletP} {
    width: ${remMobile(71)};
    height: ${remMobile(58)};
  }

  ${p => p.opened && css`
    ${ButtonInner} {
      top: 50% !important;
      transform: rotate(${180 + 45 + 90}deg);
      transition:
        transform ${transformDuration}s ${ease} ${positionDuration}s,
        top ${positionDuration}s ${ease};

      &:nth-last-of-type(3) {
        transform: rotate(${180 + 45}deg);
      }
    }
  `}
`

const Header = ({ menuOpened, updateStoreValue }) => {
  const toggleMenu = useCallback(() => {
    tc.click('menu')
    updateStoreValue('menuOpened', !menuOpened)
  }, [menuOpened])

  return (
    <Wrapper>
      <Button onClick={ toggleMenu } opened={ menuOpened }>
        <ButtonInner />
        <ButtonInner>Ouvrir / Fermer le menu</ButtonInner>
        <ButtonInner />
      </Button>
      <Logo to='/' onClick={ () => tc.click('accueil') }>Crédit Agricole</Logo>
    </Wrapper>
  )
}

export default connect('menuOpened')(React.memo(Header))
