import React, { useCallback, useEffect, useRef, useState } from 'react'

import { css, Global } from '@emotion/react'
import { AnimatePresence, motion } from 'framer-motion'
import {
  BrowserRouter,
  MemoryRouter,
  useLocation,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import styled from '@emotion/styled'

import global from 'styles/global'
import { connect } from 'helpers/state'
// import Cookies from 'components/cookies/Cookies'
import Menu from 'components/menu/Menu'
import Header from 'components/header/Header'
import Home from 'sections/home/Home'
import Episode from 'sections/episode/Episode'
import EpisodeEnd from 'sections/episode-end/EpisodeEnd'
import Summary from 'sections/summary/Summary'
import About from 'sections/about/About'
import Legals from 'sections/legals/Legals'
import Privacy from 'sections/privacy/Privacy'
import Footer from 'components/footer/Footer'
import Episodes from 'components/episodes/Episodes'
import { colors, fonts } from 'styles/helpers'
import RotateScreen from 'components/rotate-screen/RotateScreen'
import Advices from 'sections/advices/Advices'
import Credits from 'sections/credits/Credits'
import Popin from 'components/popin/Popin'
import ga from 'helpers/ga'
import CookiePolicy from 'sections/cookie-policy/CookiePolicy'

import { baseAnimation } from './animation'

const Router = process.env.NODE_ENV === 'development' ? BrowserRouter : MemoryRouter

const Wrapper = styled.div`
  background: ${colors.green};

  &after {
    content: 'a';
    width: 0;
    height: 0;
    opacity: .00001;
    font-family: ${fonts.alternative};
  }
`

const greenMode = css`
  html { background-color: ${colors.green}; }
`

const App = ({ mqTabletP, greenBackground }) => {
  return (
    <>
      <Global styles={ global } />
      <Global styles={ greenBackground ? greenMode : '' } />
      <Wrapper>
        <Router>
          <Header />
          <Menu />
          <Pages />
          {/* <Cookies /> */}
          <Popin />
        </Router>
      </Wrapper>
    </>
  )
}

const Pages = connect('mqTabletP, openedPDF')(React.memo(({ updateStoreValue, mqTabletP, openedPDF }) => {
  const location = useLocation()
  const currentLocation = useRef(location)
  const previousLocation = useRef(location)

  const colorRegexp = /^\/(privacy|summary|legals|a-propos|credits|conseils)/g
  const green = !!(location.pathname.match(colorRegexp) || previousLocation.current.pathname.match(colorRegexp))
  const episodes = !!location.pathname.match(/^\/($|episode-end|summary)/g)
  const footer = !location.pathname.match(/^\/episode\//g)

  const [state, setState] = useState({ episodes, footer })

  const shouldUpdate = episodes !== state.episodes || state.footer !== footer
  const shouldUpdateAsync = state && !((state.episodes && episodes !== state.episodes) || (state.footer && state.footer !== footer))

  useEffect(() => {
    previousLocation.current = currentLocation.current
    currentLocation.current = location
    ga.pageView(location)
  }, [location.pathname])

  useEffect(() => updateStoreValue('greenBackground', green), [green])

  useEffect(() => {
    if (shouldUpdate && !shouldUpdateAsync) setState({ episodes, footer })
  }, [episodes, footer])

  const onExitComplete = useCallback(() => {
    document.scrollingElement.scrollTop = 0
    if (shouldUpdate && shouldUpdateAsync) setState({ episodes, footer })
  }, [episodes, footer])

  return (
    <>
      <AnimatePresence exitBeforeEnter onExitComplete={ onExitComplete }>
        <motion.div { ...baseAnimation } key={ location.pathname }>
          <Switch location={ location }>
            <Route path='/episode-end/:episodeId/:result(a|b)' component={ EpisodeEnd } />
            <Route path='/episode/:episodeId' component={ Episode } />
            <Route path='/summary' component={ Summary } />
            <Route path='/a-propos' component={ About } />
            <Route path='/conseils' component={ Advices } />
            <Route path='/privacy' component={ Privacy } />
            <Route path='/cookie-policy' component={ CookiePolicy } />
            <Route path='/credits' component={ Credits } />
            <Route path='/legals' component={ Legals } />
            <Route path='/$' component={ Home } />
            <Redirect from='/*' to='/' />
          </Switch>
        </motion.div>
      </AnimatePresence>

      <AnimatePresence initial>
        {!openedPDF && state.episodes && <Episodes key='episodes' />}
        {!openedPDF && state.footer && !mqTabletP && <Footer key='footer' />}
      </AnimatePresence>

      {mqTabletP && <RotateScreen orientation={ state.footer ? 'portrait' : 'landscape' } />}
    </>
  )
}))

export default connect('mqTabletP, greenBackground, openedPDF')(App)
