import { each, mapValues, zipObject } from 'lodash-es'

import l10n from 'core/l10n'

import { createStore } from '../helpers/state'

const storageKey = 'store'

const keys = l10n('episodes').map(e => e.id)
const episodeProgress = zipObject(keys, keys.map(() => 0))
const episodeChoiceA = zipObject(keys.map(k => `${k}-choiceA`), keys.map(() => 0))
const episodeChoiceB = zipObject(keys.map(k => `${k}-choiceB`), keys.map(() => 0))
const episodeDuration = zipObject(keys.map(k => `${k}-previous-duration`), keys.map(() => 0))
const episodeLastChoice = zipObject(keys.map(k => `${k}-previous-choice`), keys.map(() => null))

const initialState = {
  ...episodeProgress,
  ...episodeChoiceA,
  ...episodeChoiceB,
  ...episodeDuration,
  ...episodeLastChoice
}

const encode = (a) => window.btoa(a)
const decode = (a) => window.atob(a)

const save = () => {
  const flatStore = mapValues(persistentStore, s => s.get())
  localStorage.setItem(storageKey, encode(JSON.stringify(flatStore)))
}

const load = () => {
  const data = localStorage.getItem(storageKey)
  if (!data) return save()
  const decoded = JSON.parse(decode(data))
  each(persistentStore, (s, k) => (s.value = decoded[k]))
}

const persistentStore = createStore(initialState)
each(persistentStore, s => s.listen(save))
load()

export default persistentStore
