import React from 'react'

import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { css } from '@emotion/react'

import { colors, easing, mq, rem, remMobile, snippets } from 'styles/helpers'
import { connect } from 'helpers/state'
import tc from 'helpers/tc'

const offset = 40

const Name = styled.span`
  ${snippets.transition('opacity', .3, easing.swiftOut)}
`
const Subject = styled(Name)`
  ${mq.notTabletP} {
    ${snippets.absolute('top, left, bottom')}
    opacity: 0;
    padding-left: ${rem(30)};
    display: flex;
    align-items: center;
  }

  ${mq.tabletP} {
    font-size: ${remMobile(40)};
    font-weight: normal;
  }
`
const Text = styled.span`
  ${mq.tabletP} {
    display: flex;
    flex-direction: column;
  }
`

const ButtonInner = styled.span`
  display: flex;
  position: relative;

  ${mq.notTabletP} {
    padding: 0 ${rem(30)};
    width: ${rem(296)};
    height: ${rem(88)};
    margin-left: ${rem(-offset)};
    border-radius: ${rem(44)};
    font-size: ${rem(28)};
    justify-content: space-between;
    align-items: center;

    ${p => !p.about && css`
    background-color: ${colors.green2};
    `}

    ${p => p.advices && css`
    background-color: ${colors.green3};
    width: auto;
    white-space: nowrap;
    transform: none !important;
    `}

    &, path {
      ${snippets.transition('transform, color, background-color, stroke', .3, easing.swiftOut)}
    }

    svg {
      width: ${rem(19)};
      height: ${rem(24)};
    }
  }

  ${mq.tabletP} {
    font-size: ${remMobile(120)};
    font-weight: bold;
  }

`

const Button = styled(Link)`
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 20;

  ${mq.notTabletP} {
    padding: 0 ${rem(offset)};

    &:not(:first-of-type) {
      margin-top: ${rem(28)};
    }
  }

  ${mq.tabletP} {
    &:not(:first-of-type) {
      margin-top: ${p => remMobile(p.subject ? 70 : 100)};
    }
  }

  ${mq.hover} {
    &:hover {
      ${ButtonInner} {
        background-color: ${colors.white};
        color: ${colors.green};
        transform: translateX(${rem(offset)});
      }

      ${p => p.subject && css`
      ${Name} {
      opacity: 0;
      }

      ${Subject} {
      opacity: 1;
      }
      `}

      path {
        stroke: ${colors.green} !important;
      }
    }
  }
`

const MenuButton = ({ name, subject, mqTabletP, updateStore, updateStoreValue, ...props }) => {
  const onClick = () => {
    if (props.advices) tc.click('menu-nos_conseils_de_prevention')
    else if (props.about) tc.click('menu-a_propos')
    else tc.click('menu-' + name.toLowerCase().replace('é', 'e'))
  }
  return (
    <Button key={ props.to } { ...props } subject={ subject ? 1 : 0 } onClick={ onClick }>
      <ButtonInner { ...props }>
        <Text>
          <Name>{name}</Name>
          <Subject dangerouslySetInnerHTML={ { __html: subject } } />
        </Text>
        {props.episode && !mqTabletP && (
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23 28'>
            <path fill='none' stroke='#FFF' strokeWidth='3.8' d='M2.54 2.3l18.14 11.5L2.54 25.27V2.3z' />
          </svg>
        )}
      </ButtonInner>
    </Button>
  )
}

export default connect('mqTabletP')(React.memo(MenuButton))
