import React, { useCallback, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { AnimatePresence, motion } from 'framer-motion'

import { colors, snippets, remMobile, rem } from 'styles/helpers'
import l10n from 'core/l10n'
import resize, { useResize } from 'helpers/resize'
import { baseAnimation, fadeAnimation } from 'core/animation'
import { connect } from 'helpers/state'

const Svg = styled.svg`
  width: ${remMobile(453)};
  height: ${remMobile(478)};
  flex-shrink: 0;
`

const Wrapper = styled(motion.div)`
  ${snippets.fixed('top, left, right, bottom')}
  background: ${colors.white};
  color: ${colors.green};
  z-index: 999;
  display: ${p => p.visible ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  line-height: 1.02;
  font-weight: bold;

  span {
    display: block;
    flex-grow: 0;
  }

  ${p => p.orientation !== 'landscape' ? css`
    padding: 0 10%;
    font-size: ${remMobile(137)};

    ${Svg} {
      margin-left: ${rem(80)};
    }
  ` : css`
    padding: 0 ${remMobile(140)};
    flex-direction: column;
    justify-content: space-around;
    font-size: ${remMobile(120)};

    &::before, &::after {
      content: '';
    }
  `}
`

const getOrientation = () => (resize.width() > resize.height() ? 'landscape' : 'portrait')
const fade = Object.assign(fadeAnimation(), baseAnimation)

const RotateScreen = ({ orientation = 'portrait', updateStoreValue }) => {
  const [_orientation, setOrientation] = useState(getOrientation)
  const visible = _orientation !== orientation

  useResize(useCallback(() => {
    const o = getOrientation()
    setOrientation(o)
  }, [_orientation]))

  useEffect(() => () => updateStoreValue('goodOrientation', true))
  useEffect(() => updateStoreValue('goodOrientation', !visible), [visible])

  return (
    <AnimatePresence>
      <Wrapper orientation={ orientation } key={ orientation } { ...fade } visible={ visible }>
        <span>
          {l10n(`rotate.${orientation}`)}
        </span>

        <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 453 477'>
          <path fill='#4fd87c' d='M453 289c-4 11-7 23-12 34-23 57-62 99-118 125-13 6-27 10-41 15l-3 2 2 2 2 1c2 1 5 3 3 6s-5 4-8 2l-10-5c-11-7-11-7-3-18l6-8c1-3 4-4 6-2 2 1 2 4 2 7l-2 4h5c84-26 137-81 161-164 2-8 5-9 10-4v3zM0 189l10-31A233 233 0 01170 15l4-2-3-2c-6-4-6-6 0-11h2l17 10c4 3 5 5 2 9l-9 13c-2 3-4 5-7 3-4-2-3-5-1-8l1-3h-4C100 44 49 90 20 159l-10 30-4 5c-2 1-4-2-6-3v-2zM160 435c-8 0-14-3-20-8L40 326c-14-13-13-29 0-42l113-114 25-28 20-20 30-27c5-4 8-10 12-14l34-31c12-11 27-11 38 1 34 33 69 67 102 102 12 12 12 28 0 40-19 18-39 36-56 56-13 16-26 32-42 45-25 20-46 44-69 66l-68 67c-5 5-12 8-19 8zm-3-10c8 0 13-2 16-6l41-40 192-193c9-9 9-18 0-27L306 58c-8-8-18-8-26 0L46 292c-8 8-8 18-1 26l102 101c3 3 8 5 10 6z' />
        </Svg>
      </Wrapper>
    </AnimatePresence>
  )
}

export default connect()(React.memo(RotateScreen))
