import React, { useEffect, useMemo } from 'react'

import { AnimatePresence, motion, usePresence } from 'framer-motion'
import styled from '@emotion/styled'
import { find, once } from 'lodash-es'
import { css } from '@emotion/react'

import { baseAnimation, fadeAnimation, itemAnimation, menuAnimation } from 'core/animation'
import { bezier, colors, mq, rem, remMobile, sizes, snippets } from 'styles/helpers'
import { connect } from 'helpers/state'
import l10n from 'core/l10n'
import StartButton from 'components/start-button/StartButton'

import PageBlock from './components/PageBlock'

const Wrapper = styled.div`
  ${snippets.fixed('top, left, right, bottom')}
  overflow: hidden;
`

const Inner = styled(motion.div)`
  ${snippets.absolute('top, left, bottom')}
  padding-left: ${rem(sizes.leftOffset)};
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  ${mq.tabletP} {
    padding-top: 12vh;
    padding-bottom: ${rem(40)};
    padding-left: ${remMobile(sizes.leftOffsetM)};
    padding-right: ${remMobile(sizes.leftOffsetM)};
    width: 100%;
    max-height: calc(100% - ${sizes.episodesMobileHeight});

    /* background: red; */
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: .6;
  backface-visibility: hidden;
`

const Background = styled(motion.div)`
  ${snippets.absolute('top, left, right, bottom')}
  display: block;
  pointer-events: none;
  backface-visibility: hidden;
  user-select: none;
  z-index: 0;

  ${p => !p.background || p.background.indexOf('#') === 0 ? css`

    background-color: ${p.background || colors.black};

  ` : css`

    background-image: url('${p.background}');
    background-size: cover;
    background-position: center;

    &::after {
      content: '';
      ${snippets.absolute('top, left, right, bottom')}
      background-image: linear-gradient(0deg, rgba(0, 0, 0, .97) 15%, rgba(0, 0, 0, .3) 70%);
      display: block;
      z-index: 1;
    }
  `}
`

const fade = Object.assign(fadeAnimation({ easeIn: bezier.quadOut, easeOut: bezier.quadIn, duration: .8 }), baseAnimation)
const translate = Object.assign(itemAnimation({ distance: -100 }), baseAnimation)
const translate2 = menuAnimation()

const PageLayout = ({ background, children, episodeRolled, mqTouch, openedPDF, updateStoreValue }) => {
  const episode = useMemo(() => episodeRolled && find(l10n('episodes'), ['id', episodeRolled]), [episodeRolled])

  useEffect(() => () => updateStoreValue('episodeRolled', null), [])

  const [isPresent, safeToRemove] = usePresence()
  const exitCallback = !isPresent && once(safeToRemove)

  useEffect(() => {
    if (!isPresent) setTimeout(exitCallback, 1000)
  }, [isPresent])
  // const exitCallback = !isPresent && after(2, safeToRemove)

  return (
    <Wrapper>
      <AnimatePresence onExitComplete={ exitCallback } custom={ !isPresent ? { duration: .4 } : {} }>
        {isPresent && (episodeRolled || ~background.indexOf('.mp4') ? (
          <Background key={ episodeRolled } { ...fade }>
            <Video src={ episodeRolled ? episode.video : background } playsInline loop autoPlay muted />
          </Background>
        ) : (
          <Background key='default' { ...fade } background={ background } />
        ))}

      </AnimatePresence>

      <AnimatePresence>
        {!openedPDF && isPresent && (
          <Inner { ...translate2 }>
            <AnimatePresence exitBeforeEnter initial={ false }>`
              {(episode ? (
                <PageBlock key={ episodeRolled } { ...translate } { ...episode } episode={ !!episode }>
                  {mqTouch && (
                    <StartButton episodeId={ episodeRolled } />
                  )}
                </PageBlock>
              ) : (
                React.cloneElement(children, { ...translate })
              ))}
            </AnimatePresence>
          </Inner>
        )}
      </AnimatePresence>

    </Wrapper>
  )
}

export default connect('episodeRolled, openedPDF, mqTouch')(React.memo(PageLayout))
