import store from 'stores/store'
// import tarteaucitron from 'vendors/tarteaucitron/tarteaucitron'

window.dataLayer = window.dataLayer || []

const DEBUG = false
// const waitlist = []

// const triggerWaitlist = () => {
//   if (!window.gtag) return

//   const a = waitlist
//   waitlist = []

//   a.forEach((o) => {
//     if (typeof gtag !== 'undefined') trigger(...o)
//   })
// }

const trigger = (...args) => {
  if (args[2]) args[2].anonymize_ip = true
  else args.push({ anonymize_ip: true })

  window.dataLayer.push(args)

  if (DEBUG) {
    const method = args[0]
    const pageView = method === 'config'
    console.log(// eslint-disable-line
      `%c TAG: ${pageView ? 'pageview' : args.slice(0, 2).join('/')} `,
      `background: #000; color: #${pageView ? 'bada55' : 'EB3D54'}; font-weight: bold`,
      args[2]
    )
  }
}

const pageView = () => {
  const { pathname } = window.location

  trigger('config', store.gtagId.get(), {
    page_path: pathname
  })
}

const event = (eventName, params) => {
  trigger('event', eventName, params)
}

const ga = { pageView, event }

export default ga
