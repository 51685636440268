import { createStore } from '../helpers/state'

const initialState = {
  menuOpened: false,
  episodeRolled: null,
  greenBackground: false,
  goodOrientation: true,
  openedPDF: null,
  linkPDF: null,
  backgroundPDF: null,

  gtagId: 'G-WYNKLFBBDQ'
}

export default createStore(initialState)
