import React, { useEffect } from 'react'

import { motion } from 'framer-motion'
import styled from '@emotion/styled'

import { pageAnimation2 } from 'core/animation'
import { colors, mq, rem, remMobile, sizes } from 'styles/helpers'
import CloseButton from 'components/close-button/CloseButton'
import tc from 'helpers/tc'

const Page = styled(motion.section)`
  min-height: 100vh;
  max-width: 50%;
  padding: ${rem(250)} ${rem(sizes.leftOffset)};
  background-color: ${colors.green};

  ${mq.tabletP} {
    overflow-x: hidden;
    max-width: 100%;
    padding: ${rem(100)} ${remMobile(50)};
  }
`

const Title = styled.h2`
  line-height: .89;
  font-size: ${rem(90)};
  font-weight: bold;
  margin-bottom: ${rem(70)};

  * + & {
    margin-top: ${rem(140)};
  }

  ${mq.tabletP} {
    font-size: ${rem(35)};
    margin-bottom: ${rem(30)};

    * + & {
      margin-top: ${rem(60)};
    }
  }
`
const Paragraph = styled.p`
  font-size: ${rem(28)};

  ${mq.tabletP} {
    font-size: ${rem(18)};
  }

  a {
    text-decoration: underline;
  }

  li {
    list-style: inside;
  }
`
const anim = pageAnimation2()

const Legals = () => {
  useEffect(() => { tc.page({ page: 'mentions_legales' }) }, [])

  return (
    <Page { ...anim }>
      <CloseButton />

      <Title> Mentions légales série Cyber Prévention </Title>
      <Paragraph> Ce site est édité par PACIFICA, filiale d’assurances dommages de Crédit Agricole Assurances. PACIFICA, S.A. au capital entièrement libéré de 442 524 390 euros. </Paragraph>
      <Paragraph> Le siège social de PACIFICA est situé 8-10 Boulevard de Vaugirard – 75724 Paris Cedex 15. </Paragraph>
      <Paragraph> PACIFICA est immatriculée au R.C.S de Paris sous le numéro 352 358 865. Son numéro d’identification d’assujetti à la TVA est le FR95 352 358 865.</Paragraph>
      <Paragraph> PACIFICA est une entreprise régie par le Code des Assurances et agréée par l’Autorité de Contrôle Prudentiel et de Résolution (ACPR, 4 Place de Budapest CS92459, 75436 Paris Cedex 09).</Paragraph>
      <Paragraph> Directeur de Publication et représentant légal : Thierry LANGRENEY, Directeur Général de PACIFICA. </Paragraph>
      <Paragraph> Directeur de la rédaction : Caroline NICAISE, Directrice de la Communication, de l’Innovation et de la RSE de Crédit Agricole Assurances. </Paragraph>
      <Paragraph> Contact : <a href='mailto:lesassurancesducreditagricole@ca-pacifica.fr'>lesassurancesducreditagricole@ca-pacifica.fr</a> </Paragraph>
      <Paragraph> Numéro de téléphone : 01 53 74 33 00 </Paragraph>
      <Paragraph> Hébergeur :  PROGICA S.A.S. - 91-93 boulevard Pasteur 75015 Paris - France </Paragraph>
      <Paragraph> Tél. 01 57 72 74 10 </Paragraph>

      <Title> Mise en garde et responsabilité </Title>
      <Paragraph> En accédant au présent site Internet et aux informations qu’il contient, l’internaute, ci-après désigné « Utilisateur » reconnait avoir pris connaissance des présentes conditions d’utilisation du Site et s’engage à les respecter. </Paragraph>
      <Paragraph> Ces dispositions ne le déchargent pas des obligations qu’il contracte en accédant par un lien à une page ou un site Internet tiers. </Paragraph>
      <Paragraph> Le Site Web est réservé à l'usage privé de chaque participant au jeu « Série Cyber Prévention ». </Paragraph>
      <Paragraph> Chaque participant au jeu « Série Cyber Prévention » est seul responsable de l'usage qu'il fait des informations fournies à partir du Site et PACIFICA ne pourra en aucun cas être tenu responsable de tous dommages directs ou indirects découlant de l'utilisation de ces informations par le participant. PACIFICA ne garantit pas que le Site sera accessible de manière continue. Les sites de tiers, leur contenu et/ou les documents accessibles à partir des liens hypertextes publiés sur le Site ou dans les documents téléchargeables à partir de ce dernier sont la responsabilité exclusive de leur(s) auteur(s) et ne sauraient engager la responsabilité de PACIFICA. Les participants ne sont pas autorisés à créer un lien hypertexte vers le Site et/ou une page quelconque du Site et/ou d'un fichier s'y trouvant sans l'accord préalable, écrit et exprès de PACIFICA. </Paragraph>

      <Title> Protection des données personnelles </Title>
      <Paragraph> Consultez la Politique de protection des données.  </Paragraph>

      <Title> Propriété intellectuelle </Title>
      <Paragraph> Le Site doit être considéré comme un tout indissociable. Ainsi, le site, sa structure générale, et chacun des éléments qui le compose, et notamment les textes, articles, lettres d'informations, communiqués, présentations, brochures, illustrations, dessins, graphismes, photographies, programmes informatiques, animations, etc., sont la propriété intellectuelle exclusive de PACIFICA. </Paragraph>
      <Paragraph> L’ensemble de ce Site relève de la législation française sur les droits d’auteur et de la propriété intellectuelle. </Paragraph>
      <Paragraph> Les noms, marques et enseignes cités sur ce site sont la propriété de leurs déposants respectifs. Toute utilisation ou reproduction, totale ou partielle, du site, des éléments qui le composent et/ou des informations qui y figurent, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par le Code de la propriété intellectuelle. </Paragraph>
      <Paragraph> Les participants au jeu « Série Cyber Prévention » ne sont autorisés qu'à représenter le Site sur l'écran de leur ordinateur personnel, imprimer les pages du Site pour leur usage personnel et reproduire temporairement les fichiers qui le constituent dans la mémoire cache de cet ordinateur aux seules fins de faciliter la consultation du Site. </Paragraph>
      <Paragraph> En particulier et sous réserve des dispositions de l'alinéa précédent, le participant au jeu « Série Cyber Prévention » n'est pas autorisé à reproduire, représenter, modifier, traduire et/ou adapter, partiellement ou totalement, à titre onéreux ou gratuit, le Site et chacun des éléments qui le compose, et reproduire et/ou représenter de telles traductions, adaptations et modifications, partiellement ou totalement, à titre onéreux ou gratuit, sans l'accord préalable écrit de PACIFICA. </Paragraph>
      <Paragraph> Par exception aux alinéas précédents, PACIFICA autorise les participants au Jeu « Série Cyber Prévention » à télécharger sur le disque dur de leur ordinateur personnel les éventuelles lettres d'informations ou les autres documents qui sont mis à la disposition des participants sous la forme de fichiers électroniques spécifiquement en vue de leur téléchargement. Ces fichiers ne peuvent être téléchargés qu'à partir de liens hypertextes reproduisant la mention "télécharger ce document ici" ou toute autre mention manifestant l'autorisation donnée aux Utilisateurs de télécharger ces fichiers. </Paragraph>
      <Paragraph> Dans ce cas, et sauf disposition contraire expresse reproduite sur chacun des documents susvisés, le participant au jeu « Série Cyber Prévention » ne sera autorisé qu'à détenir une copie de ces documents et qu'à imprimer ces documents pour un usage strictement privé. Il s'interdit en particulier de diffuser à des tiers ces documents, y compris par envoi par courrier électronique, par impression et distribution sur un support tangible et par mise à disposition sur un serveur installé sur un réseau public ou privé. </Paragraph>

      <Title> Entreprises ou organismes cités </Title>
      <Paragraph> Les informations relatives aux organismes, aux entreprises et aux personnes sur le site jeu « Série Cyber Prévention » <a href='www.prevention-serie-cyber.credit-agricole.fr' target='_blank' rel='noreferrer'>www.prevention-serie-cyber.credit-agricole.fr</a> sont constituées et mises à jour sur un mode déclaratif et n'engagent en rien la responsabilité de l'éditeur. L'utilisation des informations relatives aux entreprises pour des objectifs commerciaux (offres de services, etc.) est interdite. </Paragraph>
      <Paragraph> Leur utilisation massive et récurrente pour un objectif autre que commercial doit au préalable faire l'objet d'une demande d'autorisation. </Paragraph>

      <Title> Clause attributive de compétence </Title>
      <Paragraph> Ce site est soumis à la loi française. En cas de litige, les tribunaux français seront seuls compétents. </Paragraph>

    </Page>
  )
}

export {
  Page as LegalPage,
  Title as LegalTitle,
  Paragraph as LegalParagraph
}

export default React.memo(Legals)
