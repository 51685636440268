import React, { useEffect, useMemo } from 'react'

import { motion } from 'framer-motion'
import styled from '@emotion/styled'
import { find, get } from 'lodash-es'
import { Link } from 'react-router-dom'

import { pageAnimation } from 'core/animation'
import { mq, rem, snippets } from 'styles/helpers'
import l10n from 'core/l10n'
import PageBlock from 'components/page-layout/components/PageBlock'
import PageLayout from 'components/page-layout/PageLayout'
import FileButton from 'components/file-button/FileButton'
import persistentStore from 'stores/persistentStore'
import Button from 'components/button/Button'
import tc from 'helpers/tc'
import RatePopin from 'components/rate-popin/RatePopin'

const Page = styled(motion.section)`
  ${snippets.absolute('top, left, right, bottom')}
`

const ButtonWrapper = styled.div`
  display: flex;
`
const ButtonColumn = styled.div`
  & + & {
    margin-left: ${rem(35)};
    ${mq.tabletP} {
      margin-left: ${rem(15)};
    }
  }

`

const Block = styled(PageBlock)`
  ${mq.notTabletP} {
    padding-top: ${rem(200)};
    padding-bottom: ${rem(150)};
    padding-right: 60%;
    width: 120%;
    max-height: 100%;
    flex-shrink: 0;
    overflow: auto;
  }
`
const BlockWrapper = styled.div`
  ${mq.notTabletP} {
    flex: 0 0 200%;
    overflow: hidden;
    height: 100%;
  }
`

const animation = pageAnimation()

const EpisodeEnd = ({ match }) => {
  const { episodeId, result } = match.params
  const episode = useMemo(() => find(l10n('episodes'), ['id', episodeId]), [episodeId])
  const loc = get(episode, `result.${result}`, {})

  // const finished = useMemo(() => every(l10n('episodes'), (e) => persistentStore[e.id].get() === -1), [])
  const link = useMemo(() => {
    const next = find(l10n('episodes'), (e) => persistentStore[e.id].get() !== -1)
    if (next) return `/episode/${next.id}`
    else return '/summary'
  }, [])

  useEffect(() => {
    tc.page({ page: 'resultat', page_chapter1: 'saison_1', page_chapter2: episode.name.toLowerCase(), resultat: loc.title.replace(/<[^>]*>?/gm, '') })
  }, [])

  const onAdviceClick = (episode, file) => () => {
    const type = ~file.name.toLowerCase().indexOf('mineur') ? 'fiche_mineur' : ~file.name.toLowerCase().indexOf('parent') ? 'fiche_parent' : 'fiche'
    tc.click(type)
    tc.page({ page: tc.episode(episode.id), page_chapter1: 'nos_conseils_de_prevention' })
    tc.page({ page: type, page_chapter1: 'nos_conseils_de_prevention', page_chapter2: tc.episode(episode.id) })
  }

  const background = 'assets/tmp/4.jpg'

  return (
    <Page { ...animation }>
      <RatePopin />
      <PageLayout background={ background }>
        <BlockWrapper>
          <Block key='default' title={ loc.title } description={ loc.description } digital={ false } more={ loc.more }>
            <ButtonWrapper>
              <ButtonColumn>
                {episode.result.files.map((file, k) => <FileButton onClick={ onAdviceClick(episode, file) } number={ k } text={ file.name } key={ k } file={ file.src } link={ file.link && { src: file.link, name: file.linkName } } background={ background } />)}
              </ButtonColumn>
              <ButtonColumn>
                <Button as={ Link } to={ link } text='Continuer' onClick={ () => tc.click('continuez') } />
              </ButtonColumn>
            </ButtonWrapper>
          </Block>
        </BlockWrapper>
      </PageLayout>
    </Page>
  )
}

export default React.memo(EpisodeEnd)
