import each from 'lodash/each'

import mq from 'styles/helpers/mq'
import text from 'helpers/text'
import { createStore } from 'helpers/state'
import resize from 'helpers/resize'

const prefix = 'mq'
const initialState = {}
const match = {}

each(mq, (size, k) => {
  if (~k.indexOf('not')) return
  const key = prefix + text.capitalize(k)
  initialState[key] = resize.width() < size
  match[key] = size.replace('@media ', '')
})

const resizeStore = createStore(initialState)
const cb = () => {
  each(resizeStore, (state, key) => {
    state.set(window.matchMedia(match[key]).matches)
  })
}

resize.add({
  resize: cb
})

cb()

export default resizeStore
