import { mapValues } from 'lodash-es'

const bezier = {}

bezier.linear = [0, 0, 1, 1]

bezier.principleIn = [0.42, 0.1, 1, 1]
bezier.principleOut = [0, 0, 0.58, 1]

bezier.swift = [.4, 0, .2, 1]
bezier.swiftOut = [.55, 0, .1, 1]
bezier.swiftOut2 = [0, 0, .2, 1]

bezier.quadIn = [0.55, 0.085, 0.68, 0.53]
bezier.cubicIn = [0.55, 0.055, 0.675, 0.19]
bezier.quartIn = [0.895, 0.03, 0.685, 0.22]
bezier.quintIn = [0.755, 0.05, 0.855, 0.06]
bezier.sineIn = [0.47, 0, 0.745, 0.715]
bezier.expoIn = [0.95, 0.05, 0.795, 0.035]
bezier.circIn = [0.6, 0.04, 0.98, 0.335]
bezier.backIn = [0.600, -0.280, 0.735, 0.045]

bezier.quadOut = [0.25, 0.46, 0.45, 0.94]
bezier.cubicOut = [0.215, 0.61, 0.355, 1]
bezier.quartOut = [0.165, 0.84, 0.44, 1]
bezier.quintOut = [0.23, 1, 0.32, 1]
bezier.sineOut = [0.39, 0.575, 0.565, 1]
bezier.expoOut = [0.19, 1, 0.22, 1]
bezier.circOut = [0.075, 0.82, 0.165, 1]
bezier.backOut = [0.175, 0.885, 0.320, 1]

bezier.quadInOut = [0.455, 0.03, 0.515, 0.955]
bezier.cubicInOut = [0.645, 0.045, 0.355, 1]
bezier.quartInOut = [0.77, 0, 0.175, 1]
bezier.quintInOut = [0.86, 0, 0.07, 1]
bezier.sineInOut = [0.445, 0.05, 0.55, 0.95]
bezier.expoInOut = [1, 0, 0, 1]
bezier.circInOut = [0.785, 0.135, 0.15, 0.86]
bezier.backInOut = [0.680, 0, 0.265, 1]

const easing = mapValues(bezier, array => `cubic-bezier(${array.join(',')})`)

export default easing
export { bezier }
