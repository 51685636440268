import Emitter from 'tiny-emitter'
import { easeOut, animate } from 'popmotion'

import resize from 'helpers/resize'

import 'scrolling-element'

let instance
let lastScrollTop = 0
let scrollLock = false

const emitter = new Emitter()

const init = () => {
  if (instance) return

  if ('scrollRestoration' in history)
    history.scrollRestoration = 'manual'

  document.scrollingElement.classList.add('scrolling-element')
  lastScrollTop = document.scrollingElement.scrollTop
  window.addEventListener('scroll', pageScroll)

  const options = { passive: false }
  document.body.addEventListener('wheel', prevent, options)
  document.body.addEventListener('mousewheel', prevent, options)
  document.body.addEventListener('touchmove', prevent, options)
  pageScroll()
}

const prevent = (event) => {
  if (scrollLock) event.preventDefault()
}

const pageScroll = (event) => {
  const _scrollTop = document.scrollingElement.scrollTop
  if (_scrollTop === lastScrollTop) return

  const _lastScrollTop = lastScrollTop
  lastScrollTop = _scrollTop
  emitter.emit('', event, _scrollTop, _lastScrollTop)
}

// ANIMATE SCROLL

const scrollTo = (scrollTopTarget, { duration = 400, target = false, limit = false } = {}) => {
  const isDefaultTarget = !target
  target = target || document.scrollingElement
  const from = target.scrollTop

  if (from === scrollTopTarget) return Promise.resolve()

  if (limit)
    scrollTopTarget = Math.min(target.scrollHeight - resize.height(), scrollTopTarget)

  return new Promise((resolve) => {
    animate({
      from,
      to: scrollTopTarget,
      duration,
      ease: easeOut,
      onUpdate: v => {
        if (isDefaultTarget) lastScrollTop = v
        target.scrollTop = v
      },
      onComplete: resolve
    })
  })
}

export default {
  init,
  lock: (parent = true) => (scrollLock = parent),
  unlock: () => (scrollLock = false),
  to: scrollTo,
  instance: {
    on: (cb) => emitter.on('', cb),
    off: (cb) => emitter.off('', cb)
  },
  scrollTop: () => lastScrollTop,
  reset: () => scroll() && (scrollLock = false)
}
