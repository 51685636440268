import React, { useEffect } from 'react'

import { pageAnimation2 } from 'core/animation'
import { LegalPage, LegalParagraph, LegalTitle } from 'sections/legals/Legals'
import CloseButton from 'components/close-button/CloseButton'
import l10n from 'core/l10n'
import Offer from 'components/offer/Offer'
import tc from 'helpers/tc'

const anim = pageAnimation2()

const About = () => {
  useEffect(() => { tc.page({ page: 'a_propos' }) }, [])

  return (
    <LegalPage { ...anim }>
      <CloseButton />

      <LegalTitle> À propos </LegalTitle>
      <LegalParagraph> Le Groupe Crédit Agricole accorde une importance particulière aux sujets de prévention et notamment autour des usages du numérique. </LegalParagraph>
      <LegalParagraph> Le dispositif Cyber Prévention que nous proposons a vocation à sensibiliser sur l'impact du numérique dans le quotidien des utilisateurs au travers de plusieurs thématiques : la dépendance aux appareils mobiles, le cyber-harcèlement et la protection des données personnelles. </LegalParagraph>
      <LegalParagraph> Ce dispositif n'a pas vocation à se substituer à l'avis et les conseils d'un professionnel. Pour plus d'informations, contactez votre conseiller. </LegalParagraph>

      <Offer link={ l10n('about.offer') } />

    </LegalPage>
  )
}

export default React.memo(About)
