import mq from './mq'
import variables, { colors, sizes, fonts } from './variables'
import snippets from './snippets'
import styling from './styling'
import easing, { bezier } from './easing'

const { liquid, rem, remMobile, grid, gridM, correction, liquidM } = snippets

export {
  mq, variables, easing, snippets, colors, sizes, fonts, styling,
  liquid, liquidM, rem, remMobile, grid, gridM, correction, bezier
}
