import React, { useMemo, useState } from 'react'

import { motion } from 'framer-motion'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { compact } from 'lodash-es'

import { colors, rem, fonts, mq, remMobile } from 'styles/helpers'
import { connect } from 'helpers/state'
import l10n from 'core/l10n'
import tc from 'helpers/tc'

const Wrapper = styled(motion.div)`
  position: relative;
  z-index: 10;
  backface-visibility: hidden;
`

const Title = styled.h1`
  font-size: ${rem(90)};
  margin-bottom: ${rem(34)};
  font-weight: bold;
  line-height: 1;
  max-width: ${rem(900)};

  ${mq.tabletP} {
    font-size: ${remMobile(120)};
    margin-bottom: ${remMobile(40)};
    max-width: initial

    /* margin-bottom: ${remMobile(60)}; */

    /* font-size: ${remMobile(137)}; */
  }

  span {
    color: ${colors.green};
    line-height: inherit;

    ${p => p.digital && css`
      font-family: ${fonts.alternative};
      text-transform: uppercase;
    `}

    ${p => p.episode && css`
      ${mq.tabletP} {
        line-height: 1;
        font-size: ${remMobile(75)};
        white-space: nowrap;
      }
    `}
  }
`

const Inner = styled.div`
  max-width: ${rem(600)};

  ${mq.tabletP} {
    max-width: ${remMobile(900)};
  }
`

const Subtitle = styled.h2`
  font-size: ${rem(28)};
  font-weight: bold;

  ${mq.tabletP} {
    font-size: ${remMobile(40)};
  }
`

const Description = styled.p`
  margin-top: ${rem(75)};
  font-size: ${rem(28)};

  & + * {
    margin-top: ${rem(75)};
  }

  ${mq.tabletP} {
    font-size: ${remMobile(40)};
    margin-top: ${remMobile(100)};

    & + * {
      margin-top: ${remMobile(60)};
    }
  }
`

const More = styled.button`
  display: inline;
  font-weight: bold;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-decoration: underline;
`

const PageBlock = ({ mqTabletP, title, subtitle, description, more, children, digital = true, episode = false, ...props }) => {
  const [opened, setOpened] = useState()
  const _title = useMemo(() => {
    let t = title
    if (mqTabletP) t = t.split(/<br.*?\/>/gmi).join(' ')
    return digital ? compact(t.split(/(?=<\/?span.*?>)/gmi)).join('&nbsp;<br/>') : t
  }, [title, digital, mqTabletP])

  return (
    <Wrapper { ...props }>
      { title && <Title digital={ digital } episode={ episode } dangerouslySetInnerHTML={ { __html: _title } } /> }
      <Inner>
        { subtitle && <Subtitle dangerouslySetInnerHTML={ { __html: subtitle } } /> }
        { description && (
          <Description>
            <span dangerouslySetInnerHTML={ { __html: description } } />{' '}
            {
              more && (opened ? (
                <>
                  <br /><br />
                  <span dangerouslySetInnerHTML={ { __html: more } } />
                </>
              ) : (
                <More onClick={ () => {
                  tc.click('afficher_la_suite')
                  setOpened(true)
                } }
                >{l10n('common.seemore')}
                </More>
              ))
            }
          </Description>
        ) }
        { children }
      </Inner>
    </Wrapper>
  )
}

export { Inner as PageBlockInner }
export default connect('mqTabletP')(React.memo(PageBlock))
