import React, { useCallback, useEffect, useRef } from 'react'

import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import PDFObject from 'PDFObject'

import { colors, mq, rem, snippets } from 'styles/helpers'
import { connect } from 'helpers/state'
import CloseButton from 'components/close-button/CloseButton'
import { baseAnimation, fadeAnimation, popinAnimation } from 'core/animation'
import l10n from 'core/l10n'
import Offer from 'components/offer/Offer'
import tc from 'helpers/tc'

const Wrapper = styled(motion.div)`
  ${snippets.fixed('top, left, bottom, right')}
  z-index: 90;
`

const Inner = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: ${rem(100)};
  backface-visibility: hidden;

  ${mq.tabletP} {
    padding-top: ${rem(50)};
  }
`
const Overlay = styled(motion.div)`
  ${snippets.fixed('top, left, bottom, right')}
  z-index: -1;
  background: ${p => p.background};
  background-size: cover;
  background-position: center;
`

const Pdf = styled.div`
  width: 80vw;
  height: 70vh;
  position: relative;

  /* &::after {
    content: '';
    width: 100%;
    display: block;
    padding-top: ${(16 / 12) * 100}%;
  } */
`

const PdfInner = styled.div`
  ${snippets.absolute('top, left, bottom, right')}
`

const Button = styled.a`
  color: ${colors.white};
  font-size: ${rem(32)};
  margin-top: ${rem(40)};
  font-weight: bold;
  line-height: 1.11;
`

const fade = fadeAnimation()
const anim = popinAnimation().variants

const Popin = ({ openedPDF, updateStoreValue, backgroundPDF, linkPDF }) => {
  const pdf = useRef()
  const closePopin = useCallback(() => updateStoreValue('openedPDF', null), [])

  useEffect(() => {
    if (!openedPDF) return
    PDFObject.embed(openedPDF, pdf.current, {
      pdfOpenParams: {
        navpanes: 0,
        toolbar: 0,
        statusbar: 0,
        pagemode: 'thumbs',
        view: 'FitV'
      }
    })
  }, [openedPDF])

  return (
    <AnimatePresence exitBeforeEnter>
      {openedPDF && (
        <Wrapper { ...baseAnimation } key={ openedPDF }>
          <Inner variants={ anim }>
            <CloseButton onClick={ closePopin } />
            <Pdf>
              <PdfInner ref={ pdf } />
            </Pdf>
            <Button onClick={ () => tc.click('telecharger') } href={ openedPDF } download target='_blank'>{l10n('common.download')}</Button>
            {linkPDF && <Offer onClick={ () => tc.click('offres_ca') } link={ linkPDF.src } text={ linkPDF.name } />}
          </Inner>
          <Overlay { ...fade } background={ backgroundPDF ? `url(${backgroundPDF})` : colors.green } />
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

export default connect('openedPDF, backgroundPDF, linkPDF')(React.memo(Popin))
