import React from 'react'

import { motion } from 'framer-motion'
import styled from '@emotion/styled'

import l10n from 'core/l10n'
import { pageAnimation } from 'core/animation'
import { colors, snippets, mq, rem } from 'styles/helpers'
import PageBlock from 'components/page-layout/components/PageBlock'
import PageLayout from 'components/page-layout/PageLayout'
import Form from 'components/form/Form'

const Page = styled(motion.section)`
  ${snippets.absolute('top, left, right, bottom')}
`

const Block = styled(PageBlock)`
  ${mq.notTabletP} {
    padding-top: ${rem(200)};
    padding-bottom: ${rem(150)};
    max-height: 100%;
    width: 110%;
    flex-shrink: 0;
    overflow: auto;
  }
`
const BlockWrapper = styled.div`
  ${mq.notTabletP} {
    width: 100vw;
    overflow: hidden;
    height: 100%;
  }
`

const animation = pageAnimation()

const InnerSummary = (props) => (
  <BlockWrapper>
    <Block key='default' title={ l10n('summary.title') } description={ l10n('summary.description') } { ...props }>
      <Form />
    </Block>
  </BlockWrapper>
)

const Summary = () => {
  return (
    <Page { ...animation }>

      <PageLayout background={ colors.green }>
        <InnerSummary />
      </PageLayout>

    </Page>
  )
}

export default React.memo(Summary)
