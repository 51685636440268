
import React, { useCallback, useEffect, useRef, useState } from 'react'

import styled from '@emotion/styled'
import Hls from 'hls.js'
import { AnimatePresence, motion } from 'framer-motion'

import { connect } from 'helpers/state'
import useInterval from 'hooks/useInterval'
import episodeAction from 'actions/episodeAction'
import { rem, snippets } from 'styles/helpers'
import { baseAnimation, fadeAnimation } from 'core/animation'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const Play = styled(motion.button)`
  display: block;
  width: ${rem(100)};
  height: ${rem(100)};
  margin-left: ${rem(-50)};
  margin-top: ${rem(-50)};
  position: absolute;
  background-image: url('assets/svg/play.svg');
  background-size: contain;
  z-index: 3;
  top: 50%;
  left: 50%;
  background-repeat: no-repeat;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Canvas = styled.canvas`
  ${snippets.fullscreen}
  pointer-events: none;
  opacity: ${p => p.visible ? 1 : 0};
  object-fit: cover;
  background: black;
  transition: opacity .3s linear;
`

const fade = Object.assign(fadeAnimation(), baseAnimation)

const EpisodePlayer = ({ episode, videoSrc, nextSequence, initSequence, endSequence, goodOrientation, updateStore, playing, choice }) => {
  const canvas = useRef()
  const player = useRef()
  const needInit = useRef()
  const hls = useRef()
  const [controls, setControls] = useState(false)
  const key = [videoSrc, nextSequence].join('_')

  useEffect(() => () => updateStore({ player: null }), [])

  // Init HLS
  useEffect(() => {
    const { current: video } = player
    updateStore({ player: null })

    if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = videoSrc
      video.load()
    } else if (Hls.isSupported()) {
      const _hls = new Hls()
      _hls.startLevel = hls.current ? hls.current.nextLoadLevel : 3
      _hls.loadSource(videoSrc)
      _hls.attachMedia(video)
      _hls.on(Hls.Events.MANIFEST_PARSED, onManifestParsed)
      hls.current = _hls

      return () => {
        _hls.off(Hls.Events.MANIFEST_PARSED, onManifestParsed)
        _hls.destroy()
      }
    }
  }, [key])

  useInterval(useCallback(() => {
    if (!player.current) return
    const { paused, currentTime, duration } = player.current
    if (!paused && !!duration) updateStore({ time: currentTime })
  }, []), .05)

  useEffect(() => {
    if (choice) return
    if (goodOrientation) play()
    else player.current.pause()
  }, [goodOrientation])

  const play = useCallback(() => {
    const { current: video } = player
    const play = video.play()

    if (play.then) {
      play.then(onPlaying)
        .catch((e) => setControls(true))
    }

    if (!goodOrientation) {
      setTimeout(() => {
        if (!video.paused) video.pause()
      }, 5)
    }
  }, [goodOrientation])

  // Callbacks
  const onManifestParsed = useCallback(() => {
    play()
  }, [play])

  const onLoadedMetadata = useCallback(() => {
    // player.current.playbackRate = 5
    if (player.current.paused) play()
    if (player.current.duration) initSequence(player.current.duration, videoSrc)
    else needInit.current = true
  }, [play])

  const onPlaying = useCallback(() => {
    if (!player.current.duration) return window.requestAnimationFrame(onPlaying)

    if (needInit.current) {
      initSequence(player.current.duration)
      needInit.current = false
    }
    updateStore({ playing: true, started: true })
    setControls(false)
  }, [])

  const onEnded = useCallback(() => {
    player.current.pause()
    updateStore({ playing: false })
    endSequence()
    drawCanvas()
  }, [])

  const drawCanvas = () => {
    const { current: _canvas } = canvas
    const ctx = _canvas.getContext('2d')
    _canvas.width = player.current.videoWidth
    _canvas.height = player.current.videoHeight
    ctx.drawImage(player.current, 0, 0)
  }

  return (
    <Wrapper>
      <AnimatePresence>
        {controls && (<Play { ...fade } onClick={ play } />)}
      </AnimatePresence>
      <Canvas ref={ canvas } visible={ !playing } />
      <Video ref={ player } autoPlay playsInline { ...{ key, onLoadedMetadata, onEnded } } />
    </Wrapper>
  )
}

export default connect('episode, choice, playing, videoSrc, nextSequence, goodOrientation', episodeAction)(React.memo(EpisodePlayer))
