import React, { useCallback, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { every } from 'lodash-es'

import l10n from 'core/l10n'
import { colors, easing, mq, rem } from 'styles/helpers'
import Button from 'components/button/Button'

const Wrapper = styled.div`
`

const Block = styled.div`
  & + & {
    margin-top: ${rem(58)};
  }

  ${mq.tabletP} {
    & + & {
      margin-top: ${rem(40)};
    }
  }
`

const Question = styled.h5`
  margin-bottom: ${rem(28)};
  font-size: ${rem(28)};
  font-weight: bold;

  ${mq.tabletP} {
    margin-bottom: ${rem(20)};
    font-size: ${rem(20)};
  }
`

const Choices = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Valid = styled(Button)`
  margin: ${rem(58)} 0 ${rem(100)};
  opacity: .5;
  pointer-events: none;

  ${mq.tabletP} {
    margin: ${rem(40)} 0;
  }

  ${p => p.valid && css`
    opacity: 1;
    pointer-events: auto;
  `}
`

const Choice = styled.button`
  flex: 0 0 ${rem(82)};
  display: block;
  height: ${rem(75)};
  background-color: ${colors.white};
  color: ${colors.green};
  padding: 0 ${rem(20)};
  font-size: ${rem(28)};
  white-space: nowrap;
  margin-right: ${rem(8)};
  transition: color .3s ${easing.swiftOut}, background .3s ${easing.swiftOut};

  &:not(:last-child) {
    margin-bottom: ${rem(8)};
  }

  ${mq.tabletP} {
    flex: 0 0 ${rem(45)};
    height: ${rem(45)};
    padding: 0 ${rem(15)};
    font-size: ${rem(20)};
    margin-right: ${rem(4)};

    &:not(:last-child) {
      margin-bottom: ${rem(4)};
    }
  }

  ${p => p.clicked && css`
    background: ${colors.green2};
    color: ${colors.white};
  `}
`

const Form = () => {
  const survey = l10n('survey.questions').map(() => useState([]))
  const [valid, setValid] = useState(false)
  const history = useHistory()

  const deps = survey.map((a) => a[0])

  useEffect(() => {
    const note = localStorage.getItem('note')
    if (note) {
      const [choice, setChoices] = survey[0]
      setChoices([note])
    }
  }, [])

  const onChoice = useCallback((_choice, index) => () => {
    const [choices, setChoices] = survey[index]
    // if (index === 3) {
    //   let newArray
    //   if (~choices.indexOf(_choice)) newArray = without(choices, _choice)
    //   else newArray = [...choices, _choice]
    //   setChoices(newArray)
    // } else {
    setChoices([_choice])
    // }
  }, deps)

  useEffect(() => {
    setValid(every(deps, (a) => a && a.length))
  }, deps)

  const onToken = (token) => {
    const xhr = new XMLHttpRequest()
    const params = `a=${deps[0].join(', ')}&b=${deps[1].join(', ')}&c=${deps[2].join(', ')}&d=${deps[3].join(', ')}&token=${token}`
    xhr.open('POST', '/api.php')
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    xhr.onload = () => {
      history.push('/')
    }
    xhr.send(params)
  }

  const onValid = useCallback(() => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', '/api.php')
    xhr.onload = () => onToken(xhr.responseText)
    xhr.send()
  }, deps)

  return (
    <Wrapper>
      {l10n('survey.questions').map((question, i) => (
        <Block key={ i }>
          <Question>{question.title}</Question>
          <Choices>
            {question.choices.map((choice, j) => (
              <Choice onClick={ onChoice(choice, i) } clicked={ ~survey[i][0].indexOf(choice) } key={ j }>{choice}</Choice>
            ))}
          </Choices>
        </Block>
      ))}

      <Valid valid={ valid } onClick={ valid ? onValid : null } text={ l10n('survey.valid') } />
    </Wrapper>
  )
}

export default React.memo(Form)
