import React, { useCallback } from 'react'

import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import PDFObject from 'PDFObject'

import { rem, colors, mq, remMobile } from 'styles/helpers'
import l10n from 'core/l10n'
import Button from 'components/button/Button'
import { connect } from 'helpers/state'
import ga from 'helpers/ga'

const CustomButton = styled(Button)`
  ${mq.hover} {
    &:hover {
      path {
        fill: ${colors.green};
      }

      circle {
        stroke: ${colors.green};
      }
    }
  }
`
const Svg = styled.svg`
  width: ${rem(68)};
  height: ${rem(68)};
  flex-shrink: 0;
  margin-left: ${rem(20)};

  ${mq.tabletP} {
    width: ${remMobile(85)};
    height: ${remMobile(85)};
    margin-left: ${remMobile(30)};
  }

  circle {
    stroke: ${colors.white};
  }

  path {
    fill: ${colors.white};
  }
`

const FileButton = ({ file, to, number, text, background, link, updateStore, onClick }) => {
  const handleClick = useCallback((event) => {
    ga.event('open-pdf', { name: file })

    if (!PDFObject.supportsPDFs) return
    event && event.preventDefault()
    event && event.stopPropagation()
    updateStore({
      openedPDF: file,
      backgroundPDF: background || null,
      linkPDF: link || null
    })

    onClick && onClick(event)
  }, [file])

  return (
    <CustomButton { ...(file ? { target: '_blank', href: file } : { to, as: Link }) } text={ text || l10n('common.advices')[number] } onClick={ handleClick } hover={ l10n('common.seenote') }>
      <Svg viewBox='0 0 72 72'>
        <path d='M21.65 35.9c0-3.92.03-7.83 0-11.74-.02-1.8.46-3.4 1.71-4.7a5.91 5.91 0 013.4-1.77c.4-.06.84-.08 1.26-.08 3.32 0 6.64.02 9.96-.01a4.6 4.6 0 013.44 1.43l7.33 7.32a4.19 4.19 0 011.4 3.22v18.58a6.04 6.04 0 01-5.78 6.01c-1.39.06-2.77.04-4.16.04H28.04c-1.39 0-2.67-.3-3.8-1.12a6.05 6.05 0 01-2.59-5.2V35.9zm16.3-16.26l-.27-.02h-10.1a4.06 4.06 0 00-3.93 4.2c-.01 8.02 0 16.04-.02 24.06a4.2 4.2 0 004.33 4.33c1.47-.02 2.94 0 4.41 0 3.85 0 7.7-.02 11.54 0A4.2 4.2 0 0048.15 48c-.05-5.92-.02-11.83-.02-17.75v-.46h-5.95a4.06 4.06 0 01-4.2-3.84c-.07-.67-.03-1.35-.03-2.02v-4.3zm9.12 8.13l-7.1-7.08c0 1.66-.02 3.45.02 5.24.03 1 .92 1.82 1.93 1.83 1.75.03 3.51 0 5.15 0z' />
        <path d='M35.92 46.09h7.03c.46 0 .8.18.99.6.16.36.12.7-.13 1.02-.25.32-.6.4-1 .4H29.08c-.13 0-.25 0-.38-.02-.58-.07-.97-.5-.94-1.05.03-.55.45-.95 1.05-.95h7.1zM35.9 33.9h7.08c.82 0 1.32.8.9 1.46-.14.23-.44.36-.7.5-.12.05-.29.02-.44.02h-13.7c-.51 0-.96-.07-1.2-.59a.97.97 0 01.87-1.39c.37-.02.75 0 1.12 0h6.07zM35.92 41.99h-7c-.45 0-.83-.12-1.05-.56-.31-.6.06-1.3.73-1.4.15-.03.3-.03.45-.03h13.7c.36 0 .72 0 1 .3.27.3.34.64.22 1-.13.4-.42.61-.82.67-.14.02-.28.02-.41.02h-6.82z' />
        <circle cx='35.9' cy='35.9' r='34' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3.8' />
      </Svg>
    </CustomButton>
  )
}

export default connect()(React.memo(FileButton))
