import React from 'react'

import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { rem, colors, mq } from 'styles/helpers'

const width = 50
const widthM = 40

const Wrapper = styled.button`
  position: fixed;
  top: ${rem(30)};
  left: 50vw;
  margin-left: ${rem(-width / 2)};
  width: ${rem(width)};
  height: ${rem(width)};
  border-radius: 50%;
  background: ${colors.white};
  z-index: 80;
  overflow: hidden;
  text-indent: 99em;
  white-space: nowrap;

  ${mq.tabletP} {
    top: ${rem(15)};
    margin-left: ${rem(-widthM / 2)};
    width: ${rem(widthM)};
    height: ${rem(widthM)};
  }

  &::after, &::before {
    content: '';
    position: absolute;
    height: ${rem(4)};
    border-radius: ${rem(2)};
    width: 50%;
    background: ${colors.green};
    top: 50%;
    left: 50%;

    ${mq.tabletP} {
      height: ${rem(3)};
      border-radius: ${rem(1.5)};
    }
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`

const CloseButton = ({ onClick, ...props }) => {
  const props2 = onClick ? { onClick } : { to: '/', as: Link }

  return (
    <Wrapper { ...props2 } { ...props }>
      Fermer
    </Wrapper>
  )
}

export default React.memo(CloseButton)
