import React from 'react'

import { motion } from 'framer-motion'
import styled from '@emotion/styled'

import { rem, colors, remMobile, mq } from 'styles/helpers'
import l10n from 'core/l10n'
import { episodesAnimation } from 'core/animation'

import EpisodeButton from './components/EpisodeButton'

const Wrapper = styled(motion.aside)`
  position: fixed;
  right: 0;
  bottom: 0;
  color: ${colors.grey};
  z-index: 20;

  ${mq.tabletP} {
    width: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    background-color: transparent;
    top: ${rem(-240)};
    right: 0;
    height: ${rem(240)};
    width: ${rem(120)};
    border-bottom-right-radius: ${rem(120)};
    box-shadow: 0 ${rem(120)} 0 0 ${colors.white};

    ${mq.tabletP} {
      top: ${remMobile(-400)};
      height: ${remMobile(400)};
      width: ${remMobile(200)};
      border-bottom-right-radius: ${remMobile(200)};
      box-shadow: 0 ${remMobile(200)} 0 0 ${colors.white};
    }
  }

`

const Inner = styled(motion.div)`
  /* max-width: ${rem(770)}; */
  padding: ${rem(40)} ${rem(70)};
  background: ${colors.white};
  border-radius: ${rem(50)} 0 0 0;
  position: relative;
  z-index: 1;

  ${mq.tabletP} {
    padding: ${remMobile(60)} ${remMobile(75)};
    border-radius: ${remMobile(90)} 0 0 0;
  }
`

const Title = styled(motion.h3)`
  position: relative;
  font-weight: bold;
  font-size: ${rem(28)};
  line-height: 1.09;
  text-align: left;
  margin-bottom: ${rem(40)};
  color: ${colors.green};

  ${mq.tabletP} {
    font-size: ${remMobile(47)};
    margin-bottom: ${remMobile(60)};
  }

  span {
    display: inline-block;
    background: ${colors.white};
    position: relative;
    z-index: 1;
    padding-right: 14px;
  }

  &::after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    border-bottom: 2px solid ${colors.green};

    ${mq.tabletP} {
      border-width: ${remMobile(1)};
    }
  }

`

const List = styled.div`
  display: flex;
  ${mq.tabletP} {
    justify-content: space-between;
  }
`

const Button = styled(EpisodeButton)`
  ${mq.notTabletP} {
    &:not(:first-of-type) {
      margin-left: ${rem(40)};
    }
  }
`

const animation = episodesAnimation()

const Episodes = () => {
  return (
    <Wrapper { ...animation }>
      <Inner>
        <Title><span>{l10n('common.episodes')}</span></Title>
        <List>
          {l10n('episodes').map((e, k) => (<Button key={ k } episode={ e } />))}
        </List>
      </Inner>
    </Wrapper>
  )
}

export default React.memo(Episodes)
