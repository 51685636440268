import { css } from '@emotion/react'

// import tarteaucitron from 'vendors/tarteaucitron/css/tarteaucitron.style'

import { fonts, colors, sizes, rem, mq } from './helpers'
import fontsDeclaration from './fonts/fonts'

export default css`
  html {
    width: 100%;
    -webkit-text-size-adjust: none;
    background-color: ${colors.black};
    background-size: cover;
    background-position: center;
    color: ${colors.white};
    font-family: ${fonts.main};
    font-size: ${sizes.rem.reference * .8}px;

    ${mq.small} {
      font-size: ${sizes.rem.reference * .6}px;
    }

    ${mq.tablet} {
      font-size: ${sizes.rem.reference * .5}px;
    }

    ${mq.tabletP} {
      font-size: ${sizes.rem.reference * 1.3}px;
    }

    ${mq.phone} {
      font-size: ${sizes.rem.reference}px;
    }

    ${mq.smallPhone} {
      font-size: ${sizes.rem.reference * .9}px;
    }
  }

  body {
    /* position: fixed;
    width: 100%;
    height: 100%; */
    margin: 0;
    font-size: ${rem(14)};

    ${mq.tabletP} {
      font-size: ${rem(12)};
    }
  }

  html, body {

  }

  .scrollingElement {
    overscroll-behavior: none;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  * {
    text-rendering: optimizeLegibility;
    text-rendering: geometricPrecision;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    box-sizing: border-box;
    background-repeat: no-repeat;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: normal;
    margin: 0;
  }

  button {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    display: block;
    background: transparent;
    padding: 0;
    font-size: inherit;
  }

  button, a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }

  input, button, a {
    &:focus, &:active {
      outline: none;
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
  }

  .hidden {
    position: fixed;
    left: -100vh;
    visibility: hidden;
    pointer-events: none;
  }

  iframe {
    /* pointer-events: none; */
  }

  ${fontsDeclaration}

  /* ${tarteaucitron} */
`
