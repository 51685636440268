import ga from 'helpers/ga'
import tc from 'helpers/tc'

import { createStore } from '../helpers/state'

import persistentStore from './persistentStore'

const initialState = {
  videoSrc: '',
  time: 0,

  sequence: 0,
  nextSequence: 0,

  offset: 0,
  nextOffset: 0,
  previousOffset: 0,

  duration: 1,
  previousDuration: 0,

  choiceA: 0,
  choiceB: 0,

  started: false,
  playing: false,
  choice: false,
  end: false
}

export default (datas) => {
  const { episode } = datas

  const status = persistentStore[episode.id].get()
  const choiceA = persistentStore[episode.id + '-choiceA'].get()
  const choiceB = persistentStore[episode.id + '-choiceB'].get()
  const duration = persistentStore[episode.id + '-previous-duration'].get()
  const lastChoice = persistentStore[episode.id + '-previous-choice'].get()

  const nextSequence = Math.max(0, status)
  const first = nextSequence === 0
  const videoSrc = first ? episode.sequences[0].url : episode.sequences[nextSequence - 1][lastChoice]
  const nextOffset = first ? 0 : episode.sequences[nextSequence - 1][lastChoice + 'Offset']

  const custom = { videoSrc, nextSequence, nextOffset, choiceA, choiceB, duration }
  const state = Object.assign({}, initialState, datas, custom)

  ga.event('launch-episode', { id: episode.id, status: status < 0 ? 'restart' : status > 0 ? 'resume' : 'start' })
  if (status <= 0) tc.page({ page: 'etape_1', page_chapter1: 'saison_1', page_chapter2: episode.name.toLowerCase() })

  const store = createStore(state)
  store.duration.listen((v, p) => store.previousDuration.set(p))
  store.offset.listen((v, p) => store.previousOffset.set(p))

  return store
}
