import React, { useMemo } from 'react'

import styled from '@emotion/styled'
import { AnimatePresence, motion, useIsPresent } from 'framer-motion'
import { css } from '@emotion/react'
import { shuffle } from 'lodash-es'

import { colors, easing, mq, rem, snippets } from 'styles/helpers'
import { connect } from 'helpers/state'
import episodeAction from 'actions/episodeAction'
import { baseAnimation, choiceAnimation } from 'core/animation'

const Wrapper = styled(motion.ul)`
  ${snippets.absolute('top, left, right, bottom')}
  display: flex;
  z-index: 10;

`
const Choice = styled(motion.li)`
  height: 100%;
  width: 50%;
  background: rgba(0, 0, 0, .5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background .6s linear, color .3s ${easing.swiftOut};

  path {
    transition: stroke .3s ${easing.swiftOut};
  }

  ${snippets.hover(css`
    background: rgba(23, 23, 23, .5);
    color: ${colors.green};

    path {
      stroke: ${colors.green};
    }
  `)}
`

const ArrowSvg = styled.svg`
  display: inline-block;
  width: ${rem(70)};
  height: ${rem(70)};
  background-image: url('assets/svg/choice.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  top: ${rem(10)};
  margin-left: ${rem(25)};

  ${mq.tabletP} {
    width: ${rem(35)};
    height: ${rem(35)};
    top: ${rem(8)};
    margin-left: ${rem(10)};
  }
`

const InnerChoice = styled.div`
  width: ${rem(600)};

  ${mq.tabletP} {
    max-width: 80%;
  }
`

const Title = styled.h2`
  font-size: ${rem(90)};
  font-weight: bold;
  margin-bottom: ${rem(30)};

  ${mq.tabletP} {
    font-size: ${rem(40)};
    margin-bottom: ${rem(10)};
  }
`

const Description = styled.p`
  font-size: ${rem(28)};

  ${mq.tabletP} {
    font-size: ${rem(14)};
    margin-bottom: ${rem(30)};
  }
`

// const _itemAnimation = itemAnimation()
const animation = choiceAnimation()

const Arrow = () => (
  <ArrowSvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.05 56.05'>
    <path fill='none' stroke='#fff' strokeWidth='3.8' d='M21.65 17.23l17.05 10.8-17.05 10.8v-21.6z' />
    <path fill='none' stroke='#fff' strokeWidth='3.8' d='M28.02 1.9h.01a26.12 26.12 0 0126.12 26.12v.01a26.12 26.12 0 01-26.12 26.12h-.01A26.12 26.12 0 011.9 28.03v-.01A26.12 26.12 0 0128.02 1.9z' />
  </ArrowSvg>
)

const EpisodeChoices = ({ choice, sequence, episode, chooseSequence, updateStore }) => {
  const onClick = (key) => () => chooseSequence(key)
  const isPresent = useIsPresent()
  const loc = episode.choices[sequence]

  const choices = useMemo(() => {
    if (!loc) return
    return shuffle([
      Object.assign({ handleClick: onClick('choiceA') }, loc.a),
      Object.assign({ handleClick: onClick('choiceB') }, loc.b)
    ])
  }, [sequence, episode.id])

  return (
    <AnimatePresence>
      {(choice && isPresent) && (
        <Wrapper key={ choice } { ...baseAnimation }>
          {choices.map((c, i) => (
            <Choice onClick={ c.handleClick } key={ i } { ...animation } custom={ { inverted: true } }>
              <InnerChoice>
                <Title>
                  {c.title}
                  <Arrow />
                </Title>
                <Description>{c.description}</Description>
              </InnerChoice>
            </Choice>
          )
          )}
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

export default connect('choice,  sequence, episode', episodeAction)(React.memo(EpisodeChoices))
